import { Record, Union } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { tuple_type, lambda_type, int32_type, unit_type, record_type, string_type, bool_type, class_type, list_type, option_type, int64_type, union_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { ofArray, filter, map, append, isEmpty, singleton, empty } from "../../fable_modules/fable-library.4.5.0/List.js";
import { FSharpSet__get_IsEmpty, toList, FSharpSet__Add, FSharpSet__Remove, FSharpSet__Contains, empty as empty_1 } from "../../fable_modules/fable-library.4.5.0/Set.js";
import { equals as equals_1, compare } from "../../fable_modules/fable-library.4.5.0/BigInt.js";
import { displayExerciseRecords, displayWorkoutRecordsOnlyPlain, displayExerciseForRecording, displayWorkoutRecordsOnly, displayWorkout, displayWorkoutLog, displayClickableColumn, createFitnessListActions, FitnessAction, fitnessService, moveEntryInList, MoveAction_$reflection } from "./FitnessCommon.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Icons, Session__GetActiveWorkoutRecord, CompositeIcons, CompositeIcons__ToElement, Session__GetActiveWorkoutId, Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { uncurry2, int64ToString, equals, curry3, curry2 } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { map as map_1, some, value as value_3 } from "../../fable_modules/fable-library.4.5.0/Option.js";
import { Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import * as flex_form from "../../../src/javascript/flex-form.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../../Omnicv.Client.Common/Router.js";
import { exnToDisplayString } from "../../Omnicv.Client.Common/Utils.js";
import { Option, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_1, ISize, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Size_ISize, Color_IColor, Common_GenericOption, Screen } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { input, checkbox } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Checkbox.fs.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { empty as empty_2, singleton as singleton_1, append as append_1, delay, toList as toList_1 } from "../../fable_modules/fable-library.4.5.0/Seq.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { h5, h4, Option as Option_4, h3, h2 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Option as Option_2, button, list as list_4 } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { ExerciseRecord_$reflection, WorkoutLog__IsEmpty, FitnessHierarchy_$reflection } from "../../Omnicv.Shared/FitnessAPI.js";
import { Markdown_ToHtml_1BFEAEDB } from "../../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { Option as Option_3, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { empty as empty_3, ofList } from "../../fable_modules/fable-library.4.5.0/Map.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Fa_i as Fa_i_1 } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { Option as Option_5, switch$ } from "../../fable_modules/Fulma.Extensions.Wikiki.Switch.3.0.0/Switch.fs.js";
import { singleton as singleton_2 } from "../../fable_modules/fable-library.4.5.0/AsyncBuilder.js";
import { commonAuthService } from "../../Omnicv.Client.Common/CommonServer.js";
import { EventInfo_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { zip } from "../../fable_modules/fable-library.4.5.0/Array.js";

export class ViewState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "SelectCandidates", "Reorder", "ShowPlainHistory"];
    }
}

export function ViewState_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.ViewState", [], ViewState, () => [[], [], [], []]);
}

export class Model$2 extends Record {
    constructor(Id, Data, Entries, CandidateEntries, SelectedCandidateEntries, InProgress, ViewState, Error$) {
        super();
        this.Id = Id;
        this.Data = Data;
        this.Entries = Entries;
        this.CandidateEntries = CandidateEntries;
        this.SelectedCandidateEntries = SelectedCandidateEntries;
        this.InProgress = InProgress;
        this.ViewState = ViewState;
        this.Error = Error$;
    }
}

export function Model$2_$reflection(gen0, gen1) {
    return record_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.Model`2", [gen0, gen1], Model$2, () => [["Id", int64_type], ["Data", option_type(gen0)], ["Entries", list_type(gen1)], ["CandidateEntries", list_type(gen1)], ["SelectedCandidateEntries", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [int64_type])], ["InProgress", bool_type], ["ViewState", ViewState_$reflection()], ["Error", option_type(string_type)]]);
}

export function Model$2_Default_Z524259C1(id) {
    return new Model$2(id, void 0, empty(), empty(), empty_1({
        Compare: compare,
    }), false, new ViewState(0, []), void 0);
}

export class Msg$2 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestData", "RequestEntries", "SetState", "DataSuccess", "EntriesSuccess", "CandidateEntriesSuccess", "SubmitSelectedCandidates", "CandidateSetSuccess", "CancelCandidateSelection", "UpdateCandidate", "Failure", "DeleteRecord", "DeleteRecordSuccess", "DeleteEntry", "UnlinkEntry", "DeleteSuccess", "MoveEntry", "MoveSuccess"];
    }
}

export function Msg$2_$reflection(gen0, gen1) {
    return union_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.Msg`2", [gen0, gen1], Msg$2, () => [[["Item", int64_type]], [], [["Item", ViewState_$reflection()]], [["Item", gen0]], [["Item", list_type(gen1)]], [["Item", list_type(gen1)]], [], [["Item", unit_type]], [], [["Item", int64_type]], [["Item", class_type("System.Exception")]], [], [["Item", unit_type]], [["Item", int64_type]], [["Item", int64_type]], [["Item", unit_type]], [["Item1", int64_type], ["Item2", MoveAction_$reflection()]], [["Item", unit_type]]]);
}

export class CandidateFunctions$1 extends Record {
    constructor(Get, Set$, GetId) {
        super();
        this.Get = Get;
        this.Set = Set$;
        this.GetId = GetId;
    }
}

export function CandidateFunctions$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.CandidateFunctions`1", [gen0], CandidateFunctions$1, () => [["Get", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(gen0)])))], ["Set", lambda_type(int32_type, lambda_type(int64_type, lambda_type(list_type(int64_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))))], ["GetId", lambda_type(gen0, int64_type)]]);
}

export function Common_update(getData, getEntries, updateEntries, candidateFuncs, deleteRecord, deleteEntry, session, msg, model) {
    let profileId_3, finalIds, profileId_7;
    switch (msg.tag) {
        case 1:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, getEntries, Session__GetCurrentProfileId(session), (Item_2) => (new Msg$2(4, [Item_2])), (Item_3) => (new Msg$2(10, [Item_3])))];
        case 2: {
            const viewState = msg.fields[0];
            if (viewState.tag === 1) {
                if (candidateFuncs != null) {
                    const profileId_2 = Session__GetCurrentProfileId(session) | 0;
                    return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, empty_1({
                        Compare: compare,
                    }), true, new ViewState(1, []), model.Error), Cmd_OfAsyncWith_either((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, curry2(value_3(candidateFuncs).Get)(profileId_2), model.Id, (Item_4) => (new Msg$2(5, [Item_4])), (Item_5) => (new Msg$2(10, [Item_5])))];
                }
                else {
                    return [model, Cmd_none()];
                }
            }
            else {
                return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, model.InProgress, viewState, model.Error), Cmd_none()];
            }
        }
        case 3:
            return [new Model$2(model.Id, some(msg.fields[0]), model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, false, model.ViewState, void 0), Cmd_none()];
        case 4:
            return [new Model$2(model.Id, model.Data, msg.fields[0], model.CandidateEntries, model.SelectedCandidateEntries, false, model.ViewState, void 0), Cmd_none()];
        case 5:
            return [new Model$2(model.Id, model.Data, model.Entries, msg.fields[0], model.SelectedCandidateEntries, false, model.ViewState, void 0), Cmd_none()];
        case 8:
        case 7:
            return [new Model$2(model.Id, model.Data, model.Entries, empty(), empty_1({
                Compare: compare,
            }), false, new ViewState(0, []), model.Error), singleton((dispatch) => {
                dispatch(new Msg$2(1, []));
            })];
        case 9: {
            const candidateId = msg.fields[0];
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, FSharpSet__Contains(model.SelectedCandidateEntries, candidateId) ? FSharpSet__Remove(model.SelectedCandidateEntries, candidateId) : FSharpSet__Add(model.SelectedCandidateEntries, candidateId), model.InProgress, model.ViewState, model.Error), Cmd_none()];
        }
        case 6: {
            const candidateIds = toList(model.SelectedCandidateEntries);
            if (isEmpty(candidateIds)) {
                return [model, singleton((dispatch_1) => {
                    dispatch_1(new Msg$2(8, []));
                })];
            }
            else if (candidateFuncs != null) {
                return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), (profileId_3 = (Session__GetCurrentProfileId(session) | 0), (finalIds = append(map(value_3(candidateFuncs).GetId, model.Entries), candidateIds), Cmd_OfAsyncWith_either((x_5) => {
                    Cmd_OfAsync_start(x_5);
                }, curry3(value_3(candidateFuncs).Set)(profileId_3)(model.Id), finalIds, () => (new Msg$2(7, [void 0])), (Item_7) => (new Msg$2(10, [Item_7])))))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 16: {
            const profileId_4 = Session__GetCurrentProfileId(session) | 0;
            const newEntries = moveEntryInList(model.Entries, msg.fields[0], msg.fields[1]);
            if (!equals(model.Entries, newEntries)) {
                const ids = map((arg_4) => flex_form.getId(arg_4), newEntries);
                return [new Model$2(model.Id, model.Data, newEntries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), Cmd_OfAsyncWith_either((x_6) => {
                    Cmd_OfAsync_start(x_6);
                }, curry2(updateEntries)(profileId_4), ids, () => (new Msg$2(17, [void 0])), (Item_9) => (new Msg$2(10, [Item_9])))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 13:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), Cmd_OfAsyncWith_either((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, curry2(deleteEntry)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Msg$2(15, [void 0])), (Item_11) => (new Msg$2(10, [Item_11])))];
        case 15:
        case 17:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, false, model.ViewState, model.Error), singleton((dispatch_2) => {
                dispatch_2(new Msg$2(1, []));
            })];
        case 11:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), Cmd_OfAsyncWith_either((x_8) => {
                Cmd_OfAsync_start(x_8);
            }, curry2(deleteRecord)(Session__GetCurrentProfileId(session)), model.Id, () => (new Msg$2(12, [void 0])), (Item_13) => (new Msg$2(10, [Item_13])))];
        case 12:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, false, model.ViewState, model.Error), Router_newUrl(new Route(32, []))];
        case 14:
            if (candidateFuncs != null) {
                const finalIds_1 = filter((i) => !equals_1(i, msg.fields[0]), map(value_3(candidateFuncs).GetId, model.Entries));
                return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), (profileId_7 = (Session__GetCurrentProfileId(session) | 0), Cmd_OfAsyncWith_either((x_9) => {
                    Cmd_OfAsync_start(x_9);
                }, curry3(value_3(candidateFuncs).Set)(profileId_7)(model.Id), finalIds_1, () => (new Msg$2(15, [void 0])), (Item_15) => (new Msg$2(10, [Item_15]))))];
            }
            else {
                return [model, Cmd_none()];
            }
        case 10:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, false, model.ViewState, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new Model$2(model.Id, model.Data, model.Entries, model.CandidateEntries, model.SelectedCandidateEntries, true, model.ViewState, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(getData)(Session__GetCurrentProfileId(session)), msg.fields[0], (Item) => (new Msg$2(3, [Item])), (Item_1) => (new Msg$2(10, [Item_1])))];
    }
}

function Common_displayCandidate(selectedCandidateEntries, getBasicInfo, dispatch, record) {
    const patternInput = getBasicInfo(record);
    const id = patternInput[1];
    return columns(ofArray([new Option(4, []), new Option(1, [])]), ofArray([column(singleton(new Option_1(0, [new Screen(0, []), new ISize(5, [])])), singleton(checkbox(empty(), singleton(input(singleton(new Common_GenericOption(1, [ofArray([new DOMAttr(9, [(_arg) => {
        dispatch(new Msg$2(9, [id]));
    }]), new HTMLAttr(62, [FSharpSet__Contains(selectedCandidateEntries, id)])])]))))))), column(empty(), singleton(checkbox(empty(), singleton(patternInput[0]))))]));
}

export function Common_viewSelectCandidates(getBasicInfo, model, dispatch) {
    const children_6 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display candidates: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append_1(singleton_1(h2(empty())(singleton("Select entries to be added"))), delay(() => {
            let children;
            return append_1(isEmpty(model.CandidateEntries) ? singleton_1("No candidates available") : singleton_1((children = map((record) => Common_displayCandidate(model.SelectedCandidateEntries, getBasicInfo, dispatch, record), model.CandidateEntries), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => singleton_1(list_4(empty(), ofArray([button(ofArray([new Option_2(0, [new Color_IColor(7, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                dispatch(new Msg$2(8, []));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Cancel"]),
            }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress ? true : FSharpSet__get_IsEmpty(model.SelectedCandidateEntries)]), new Option_2(18, [(_arg_1) => {
                dispatch(new Msg$2(6, []));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Add selected"]),
            })))])))));
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

const Main_service = fitnessService;

export class Main_Model extends Record {
    constructor(Data, InProgress, Error$) {
        super();
        this.Data = Data;
        this.InProgress = InProgress;
        this.Error = Error$;
    }
}

export function Main_Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.Main.Model", [], Main_Model, () => [["Data", option_type(FitnessHierarchy_$reflection())], ["InProgress", bool_type], ["Error", option_type(string_type)]]);
}

export class Main_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestData", "DataSuccess", "Failure", "DeleteEntry", "DeleteSuccess", "MarkFavourite", "RemoveFavourite", "FavouriteRequestSuccess"];
    }
}

export function Main_Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.Main.Msg", [], Main_Msg, () => [[], [["Item", FitnessHierarchy_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", unit_type]], [["Item", int64_type]], [["Item", int64_type]], [["Item", unit_type]]]);
}

export function Main_init() {
    return [new Main_Model(void 0, false, void 0), singleton((dispatch) => {
        dispatch(new Main_Msg(0, []));
    })];
}

export function Main_update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Main_Model(msg.fields[0], false, void 0), Cmd_none()];
        case 3:
            return [new Main_Model(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(Main_service.DeleteTrainingPlan)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Main_Msg(4, [void 0])), (Item_3) => (new Main_Msg(2, [Item_3])))];
        case 5:
            return [new Main_Model(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, curry2(Main_service.MarkTrainingPlanFavourite)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Main_Msg(7, [void 0])), (Item_5) => (new Main_Msg(2, [Item_5])))];
        case 6:
            return [new Main_Model(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, curry2(fitnessService.DeleteFavourite)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Main_Msg(7, [void 0])), (Item_7) => (new Main_Msg(2, [Item_7])))];
        case 4:
        case 7:
            return [new Main_Model(model.Data, false, model.Error), singleton((dispatch) => {
                dispatch(new Main_Msg(0, []));
            })];
        case 2:
            return [new Main_Model(model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new Main_Model(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, Main_service.GetHierarchy, Session__GetCurrentProfileId(session), (Item) => (new Main_Msg(1, [Item])), (Item_1) => (new Main_Msg(2, [Item_1])))];
    }
}

function Main_trainingPlanActions(dispatch) {
    return ofArray([new FitnessAction(4, [(Item) => (new Route(35, [Item]))]), new FitnessAction(3, [(Item_1) => (new Route(34, [Item_1]))]), new FitnessAction(6, [(arg) => {
        dispatch(new Main_Msg(5, [arg]));
    }]), new FitnessAction(1, [(arg_1) => {
        dispatch(new Main_Msg(3, [arg_1]));
    }])]);
}

function Main_displayTrainingPlan(dispatch, trainingPlan) {
    return columns(ofArray([new Option(4, []), new Option(1, [])]), ofArray([column(empty(), singleton(trainingPlan.TrainingPlan.Name)), column(empty(), singleton(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(trainingPlan.TrainingPlan.Description),
        },
    }))), column(empty(), createFitnessListActions(Main_trainingPlanActions(dispatch), trainingPlan.TrainingPlan.Id))]));
}

function Main_displayFavourite(session, dispatch, data_, data__1) {
    const data = [data_, data__1];
    const favourite = data[1];
    let patternInput;
    switch (favourite.tag) {
        case 1: {
            const workout = favourite.fields[0];
            patternInput = [workout.Name, workout.Description, empty(), void 0, workout.Id];
            break;
        }
        case 2: {
            const trainingPlan = favourite.fields[0];
            patternInput = [trainingPlan.Name, trainingPlan.Description, singleton(new FitnessAction(4, [(Item) => (new Route(35, [Item]))])), void 0, trainingPlan.Id];
            break;
        }
        default: {
            const exercise = favourite.fields[0];
            patternInput = [exercise.Name, exercise.Description, empty(), (_arg) => {
                Router_modifyLocation(new Route(45, [exercise.ContentTypeId, exercise.Id, map_1(int64ToString, Session__GetActiveWorkoutId(session))]));
            }, exercise.Id];
        }
    }
    const onClick_1 = patternInput[3];
    const name = patternInput[0];
    const description = patternInput[1];
    const removeFavourite = button(singleton(new Option_2(18, [(_arg_1) => {
        if (window.confirm(`${"Confirm unlink"}:
${"Are you sure you want to remove the favourite?"}`)) {
            (() => {
                dispatch(new Main_Msg(6, [data[0]]));
            })();
        }
    }])), singleton(icon(singleton(new Option_3(0, [new Size_ISize(0, [])])), singleton(CompositeIcons__ToElement(new CompositeIcons())))));
    return columns(ofArray([new Option(4, []), new Option(1, [])]), append((onClick_1 == null) ? ofArray([column(empty(), singleton(name)), column(empty(), toList_1(delay(() => ((description !== name) ? singleton_1(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(description),
        },
    })) : singleton_1(defaultOf())))))]) : ofArray([displayClickableColumn(value_3(onClick_1), name), displayClickableColumn(value_3(onClick_1), (description !== name) ? description : "")]), singleton(column(empty(), append(createFitnessListActions(patternInput[2], patternInput[4]), singleton(removeFavourite))))));
}

function Main_displayHierarchy(session, dispatch, hierarchy) {
    const favouritesElems = map((tupledArg) => Main_displayFavourite(session, dispatch, tupledArg[0], tupledArg[1]), hierarchy.Favourites);
    const tradingPlanElems = map((trainingPlan) => Main_displayTrainingPlan(dispatch, trainingPlan), hierarchy.TrainingPlans);
    const workoutLog = WorkoutLog__IsEmpty(hierarchy.WorkoutLog) ? empty() : append(singleton(h2(empty())(singleton("Workout log"))), displayWorkoutLog(session, hierarchy.WorkoutLog));
    if (isEmpty(hierarchy.Favourites)) {
        return append(tradingPlanElems, workoutLog);
    }
    else {
        return append(tradingPlanElems, append(singleton(h2(empty())(singleton("Favourites"))), append(favouritesElems, workoutLog)));
    }
}

export function Main_view(session, model, dispatch) {
    const children_12 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display fitness data: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append_1(singleton_1(list_4(empty(), ofArray([button(ofArray([new Option_2(0, [new Color_IColor(5, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
            Router_modifyLocation(new Route(39, []));
        }])]), singleton(createElement("span", {
            children: Interop_reactApi.Children.toArray(["View workouts"]),
        }))), button(ofArray([new Option_2(0, [new Color_IColor(5, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_1) => {
            Router_modifyLocation(new Route(43, []));
        }])]), singleton(createElement("span", {
            children: Interop_reactApi.Children.toArray(["View exercises"]),
        }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_2) => {
            Router_modifyLocation(new Route(33, []));
        }])]), singleton(createElement("span", {
            children: Interop_reactApi.Children.toArray(["Add plan"]),
        }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_3) => {
            Router_modifyLocation(new Route(36, []));
        }])]), singleton(createElement("span", {
            children: Interop_reactApi.Children.toArray(["Add workout"]),
        }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_4) => {
            Router_modifyLocation(new Route(40, []));
        }])]), singleton(createElement("span", {
            children: Interop_reactApi.Children.toArray(["Add exercise"]),
        })))]))), delay(() => {
            let children_10;
            return (model.Data != null) ? singleton_1((children_10 = append(singleton(h2(empty())(singleton("Your training plans"))), Main_displayHierarchy(session, dispatch, value_3(model.Data))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))) : empty_2();
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
    });
}

const TrainingPlan_service = fitnessService;

export function TrainingPlan_init(id) {
    return [Model$2_Default_Z524259C1(id), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg$2(0, [id]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg$2(1, []));
    })]))];
}

export function TrainingPlan_update(session, msg, model) {
    return Common_update(uncurry2((profileId) => curry2(TrainingPlan_service.GetTrainingPlanWorkoutRecords)(value_3(profileId))), (profileId_1) => TrainingPlan_service.GetTrainingPlanWorkouts(profileId_1, model.Id), uncurry2((profileId_2) => curry3(TrainingPlan_service.SetWorkoutsForTrainingPlan)(profileId_2)(model.Id)), new CandidateFunctions$1(TrainingPlan_service.GetTrainingPlanWorkoutsCandidates, TrainingPlan_service.SetWorkoutsForTrainingPlan, (entry) => entry.Id), TrainingPlan_service.DeleteTrainingPlan, TrainingPlan_service.DeleteWorkout, session, msg, model);
}

function TrainingPlan_displayWorkouts(dispatch, workouts, forReorder) {
    const actions = forReorder ? ofArray([new FitnessAction(2, [(arg) => {
        dispatch(new Msg$2(14, [arg]));
    }]), new FitnessAction(0, [(arg_1) => {
        let tupledArg;
        dispatch((tupledArg = arg_1, new Msg$2(16, [tupledArg[0], tupledArg[1]])));
    }])]) : empty();
    return map((workout) => displayWorkout(actions, workout), workouts);
}

function TrainingPlan_viewMain(model, dispatch) {
    const children_10 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display training plan: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            const trainingPlan = value_3(model.Data).TrainingPlan;
            return append_1(singleton_1(h2(empty())(singleton("Training plan"))), delay(() => append_1(singleton_1(h3(singleton(new Option_4(6, [])))(singleton(trainingPlan.Name))), delay(() => append_1(singleton_1(h4(singleton(new Option_4(6, [])))(singleton(createElement("div", {
                dangerouslySetInnerHTML: {
                    __html: Markdown_ToHtml_1BFEAEDB(trainingPlan.Description),
                },
            })))), delay(() => append_1(singleton_1(list_4(empty(), ofArray([button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                Router_modifyLocation(new Route(34, [trainingPlan.Id]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Edit plan"]),
            }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_1) => {
                dispatch(new Msg$2(2, [new ViewState(1, [])]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Select workouts for plan"]),
            }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_2) => {
                dispatch(new Msg$2(2, [new ViewState(2, [])]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Reorder workouts"]),
            })))]))), delay(() => append_1(singleton_1(h3(empty())(singleton("Workouts for the training plan"))), delay(() => {
                let children_6;
                return append_1(singleton_1((children_6 = TrainingPlan_displayWorkouts(dispatch, model.Entries, false), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))), delay(() => append_1(singleton_1(h3(empty())(singleton("Workout records for the training plan"))), delay(() => {
                    let children_8;
                    return singleton_1((children_8 = displayWorkoutRecordsOnly(ofList(map((w) => [w.Id, w], model.Entries), {
                        Compare: compare,
                    }), value_3(model.Data).WorkoutRecords), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    })));
                }))));
            }))))))))));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    });
}

function TrainingPlan_viewReorder(model, dispatch) {
    const children_4 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display training plan: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            const trainingPlan = value_3(model.Data).TrainingPlan;
            return append_1(singleton_1(h2(empty())(singleton(`Training plan: ${trainingPlan.Name}`))), delay(() => append_1(singleton_1(h3(empty())(singleton("Re-order workouts for the training plan"))), delay(() => {
                let children;
                return append_1(singleton_1((children = TrainingPlan_displayWorkouts(dispatch, model.Entries, true), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), delay(() => singleton_1(list_4(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                    dispatch(new Msg$2(2, [new ViewState(0, [])]));
                }])]), singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Done"]),
                }))))))));
            }))));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function TrainingPlan_view(model, dispatch) {
    if (equals(model.ViewState, new ViewState(1, [])) && !model.InProgress) {
        return Common_viewSelectCandidates((entry) => [entry.Name, entry.Id], model, dispatch);
    }
    else if (equals(model.ViewState, new ViewState(2, [])) && !model.InProgress) {
        return TrainingPlan_viewReorder(model, dispatch);
    }
    else if ((model.Data != null) && !model.InProgress) {
        return TrainingPlan_viewMain(model, dispatch);
    }
    else {
        const children = ofArray([h2(empty())(singleton("Training plan")), "Loading data..."]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

const Workout_service = fitnessService;

export function Workout_init(id) {
    return [Model$2_Default_Z524259C1(id), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg$2(0, [id]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg$2(1, []));
    })]))];
}

export function Workout_update(session, msg, model) {
    return Common_update(uncurry2((profileId) => curry2(Workout_service.GetWorkoutRecords)(value_3(profileId))), (profileId_1) => Workout_service.GetWorkoutExercises(profileId_1, model.Id), uncurry2((profileId_2) => curry3(Workout_service.SetExercisesForWorkout)(profileId_2)(model.Id)), new CandidateFunctions$1(Workout_service.GetWorkoutExercisesCandidates, Workout_service.SetExercisesForWorkout, (entry) => entry.Id), Workout_service.DeleteWorkout, Workout_service.DeleteExercise, session, msg, model);
}

function Workout_displayExercises(workoutId, dispatch, exercises, forReorder) {
    const actions = forReorder ? ofArray([new FitnessAction(2, [(arg) => {
        dispatch(new Msg$2(14, [arg]));
    }]), new FitnessAction(0, [(arg_1) => {
        let tupledArg;
        dispatch((tupledArg = arg_1, new Msg$2(16, [tupledArg[0], tupledArg[1]])));
    }])]) : empty();
    return map((exercise) => displayExerciseForRecording(actions, workoutId, exercise), exercises);
}

function Workout_viewMain(session, model, dispatch) {
    const workout = value_3(model.Data).Workout;
    const children_14 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display workout: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append_1(singleton_1(h2(empty())(singleton("Workout"))), delay(() => append_1(singleton_1(h3(singleton(new Option_4(6, [])))(toList_1(delay(() => append_1(singleton_1(workout.Name), delay(() => ((workout.WorkoutType === 2) ? append_1(singleton_1(" "), delay(() => singleton_1(icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-recycle"])), [])))))) : empty_2()))))))), delay(() => append_1(singleton_1(h4(singleton(new Option_4(6, [])))(singleton(createElement("div", {
            dangerouslySetInnerHTML: {
                __html: Markdown_ToHtml_1BFEAEDB(workout.Description),
            },
        })))), delay(() => {
            const activeWorkoutRecord = map_1((tuple) => tuple[1], Session__GetActiveWorkoutRecord(session));
            return append_1(((workout.WorkoutType === 2) && (activeWorkoutRecord != null)) ? singleton_1(closeable("Cannot add records for all exercises with an active workout", new Class(3, []))) : empty_2(), delay(() => append_1(singleton_1(list_4(empty(), toList_1(delay(() => append_1(singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                Router_modifyLocation(new Route(37, [workout.Id]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Edit workout"]),
            })))), delay(() => append_1(singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_1) => {
                dispatch(new Msg$2(2, [new ViewState(1, [])]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Select exercises for workout"]),
            })))), delay(() => append_1(singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(8, [])]), new Option_2(18, [(_arg_2) => {
                if (window.confirm(`${"Confirm delete"}:
${"Are you sure you want to delete this entry?"}`)) {
                    (() => {
                        dispatch(new Msg$2(11, []));
                    })();
                }
            }])]), ofArray([icon(empty(), singleton(Fa_i_1(singleton(Icons__ToIcon(new Icons(2, []))), []))), createElement("span", {
                children: Interop_reactApi.Children.toArray(["Delete workout"]),
            })]))), delay(() => append_1(singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_3) => {
                dispatch(new Msg$2(2, [new ViewState(2, [])]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Reorder exercises"]),
            })))), delay(() => ((activeWorkoutRecord == null) ? append_1((workout.WorkoutType === 2) ? singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(4, [])]), new Option_2(16, [model.InProgress ? true : isEmpty(model.Entries)]), new Option_2(18, [(_arg_4) => {
                Router_modifyLocation(new Route(47, [workout.Id]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Add record for all exercises"]),
            })))) : empty_2(), delay(() => singleton_1(button(ofArray([new Option_2(0, [new Color_IColor(7, [])]), new Option_2(16, [model.InProgress ? true : isEmpty(model.Entries)]), new Option_2(18, [(_arg_5) => {
                Router_modifyLocation(new Route(48, [workout.Id]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Start workout"]),
            })))))) : empty_2()))))))))))))), delay(() => append_1(singleton_1(h3(empty())(singleton("Exercises for the workout"))), delay(() => {
                let children_12;
                let history;
                const workoutRecords = value_3(model.Data).WorkoutRecords;
                const showPlainHistory = equals(model.ViewState, new ViewState(3, []));
                history = (!isEmpty(workoutRecords) ? append(ofArray([h4(singleton(new Option_4(6, [])))(singleton("History")), switch$(ofArray([new Option_5(6, [showPlainHistory]), new Option_5(10, [(_arg_6) => {
                    dispatch(new Msg$2(2, [showPlainHistory ? (new ViewState(0, [])) : (new ViewState(3, []))]));
                }]), new Option_5(12, ["workout-history-view"]), new Option_5(2, []), new Option_5(0, [new Color_IColor(4, [])])]), singleton("Show plain workout history")), createElement("small", {
                    children: ["Plain view of the workout history allows you to edit individual records"],
                })]), showPlainHistory ? displayWorkoutRecordsOnlyPlain(empty_3({
                    Compare: compare,
                }), workoutRecords) : displayWorkoutRecordsOnly(empty_3({
                    Compare: compare,
                }), workoutRecords)) : empty());
                return singleton_1((children_12 = append(Workout_displayExercises(model.Id, dispatch, model.Entries, false), history), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                })));
            }))))));
        }))))))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    });
}

function Workout_viewReorder(_session, model, dispatch) {
    const workout = value_3(model.Data).Workout;
    const children_4 = toList_1(delay(() => {
        let matchValue;
        return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display workout: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append_1(singleton_1(h2(empty())(singleton(`Workout: ${workout.Name}`))), delay(() => append_1(singleton_1(h3(empty())(singleton("Re-order exercises for the workout"))), delay(() => {
            let children;
            return append_1(singleton_1((children = Workout_displayExercises(model.Id, dispatch, model.Entries, true), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => singleton_1(list_4(empty(), singleton(button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                dispatch(new Msg$2(2, [new ViewState(0, [])]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Done"]),
            }))))))));
        }))))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function Workout_view(session, model, dispatch) {
    if (equals(model.ViewState, new ViewState(1, [])) && !model.InProgress) {
        return Common_viewSelectCandidates((entry) => [entry.Name, entry.Id], model, dispatch);
    }
    else if (equals(model.ViewState, new ViewState(2, [])) && !model.InProgress) {
        return Workout_viewReorder(session, model, dispatch);
    }
    else if ((model.Data != null) && !model.InProgress) {
        return Workout_viewMain(session, model, dispatch);
    }
    else {
        const children = ofArray([h2(empty())(singleton("Workout")), "Loading data..."]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

const Exercise_service = fitnessService;

export function Exercise_init(id) {
    return [Model$2_Default_Z524259C1(id), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg$2(0, [id]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg$2(1, []));
    })]))];
}

export function Exercise_update(session, msg, model) {
    return Common_update(Exercise_service.GetExercise, (profileId) => singleton_2.Delay(() => singleton_2.ReturnFrom(Exercise_service.GetExerciseRecords(profileId, model.Id))), (_arg_1, _arg_2) => {
        throw new Error("Not supported");
    }, void 0, fitnessService.DeleteExercise, (profileId_1, id) => singleton_2.Delay(() => singleton_2.Bind(commonAuthService.DeleteRecord(profileId_1, id), (_arg) => singleton_2.Return(void 0))), session, msg, model);
}

export function Exercise_view(session, model, dispatch) {
    if ((model.Data != null) && !model.InProgress) {
        const children_8 = toList_1(delay(() => {
            let matchValue;
            return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display exercise: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
                const exercise = value_3(model.Data);
                const name = exercise.Name;
                return append_1(singleton_1(h2(empty())(singleton("Exercise"))), delay(() => append_1(singleton_1(h3(singleton(new Option_4(6, [])))(singleton(name))), delay(() => append_1((exercise.Description !== name) ? singleton_1(h4(singleton(new Option_4(6, [])))(singleton(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: Markdown_ToHtml_1BFEAEDB(exercise.Description),
                    },
                })))) : empty_2(), delay(() => append_1((exercise.Instructions !== name) ? singleton_1(h5(singleton(new Option_4(6, [])))(singleton(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: Markdown_ToHtml_1BFEAEDB(exercise.Instructions),
                    },
                })))) : empty_2(), delay(() => append_1(singleton_1(list_4(empty(), ofArray([button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                    Router_modifyLocation(new Route(41, [exercise.Id]));
                }])]), singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Edit exercise"]),
                }))), button(ofArray([new Option_2(0, [new Color_IColor(8, [])]), new Option_2(18, [(_arg_1) => {
                    if (window.confirm(`${"Confirm delete"}:
${"Are you sure you want to delete this entry?"}`)) {
                        (() => {
                            dispatch(new Msg$2(11, []));
                        })();
                    }
                }])]), ofArray([icon(empty(), singleton(Fa_i_1(singleton(Icons__ToIcon(new Icons(2, []))), []))), createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Delete exercise"]),
                })])), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_2) => {
                    const workoutIdOpt = map_1(int64ToString, Session__GetActiveWorkoutId(session));
                    Router_modifyLocation(new Route(45, [value_3(model.Data).ContentTypeId, exercise.Id, workoutIdOpt]));
                }])]), singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Log exercise"]),
                })))]))), delay(() => append_1(singleton_1(h3(empty())(singleton("Records for the exercise"))), delay(() => {
                    let children_6;
                    return singleton_1((children_6 = displayExerciseRecords(session, (arg) => {
                        dispatch(new Msg$2(13, [arg]));
                    }, model.Entries), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    })));
                }))))))))))));
            }));
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        });
    }
    else {
        const children_10 = ofArray([h2(empty())(singleton("Exercise")), "Loading data..."]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        });
    }
}

const ExerciseRecord_service = fitnessService;

export class ExerciseRecord_Model extends Record {
    constructor(Id, Data, InProgress, Error$) {
        super();
        this.Id = Id;
        this.Data = Data;
        this.InProgress = InProgress;
        this.Error = Error$;
    }
}

export function ExerciseRecord_Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.ExerciseRecord.Model", [], ExerciseRecord_Model, () => [["Id", int64_type], ["Data", option_type(tuple_type(EventInfo_$reflection(), ExerciseRecord_$reflection()))], ["InProgress", bool_type], ["Error", option_type(string_type)]]);
}

export function ExerciseRecord_Model_Default_Z524259C1(id) {
    return new ExerciseRecord_Model(id, void 0, false, void 0);
}

export function ExerciseRecord_Model__GetExerciseId(this$) {
    return flex_form.getExerciseId(value_3(this$.Data)[1]);
}

export class ExerciseRecord_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestData", "DataSuccess", "Failure", "DeleteEntry", "DeleteSuccess"];
    }
}

export function ExerciseRecord_Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.MainFitnessView.Component.ExerciseRecord.Msg", [], ExerciseRecord_Msg, () => [[["Item", int64_type]], [["Item1", EventInfo_$reflection()], ["Item2", ExerciseRecord_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", unit_type]]]);
}

export function ExerciseRecord_init(_contentTypeId, id) {
    return [ExerciseRecord_Model_Default_Z524259C1(id), singleton((dispatch) => {
        dispatch(new ExerciseRecord_Msg(0, [id]));
    })];
}

export function ExerciseRecord_update(session, msg, model) {
    let profileId;
    switch (msg.tag) {
        case 1:
            return [new ExerciseRecord_Model(model.Id, [msg.fields[0], msg.fields[1]], false, void 0), Cmd_none()];
        case 3:
            return [new ExerciseRecord_Model(model.Id, model.Data, true, model.Error), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, (profileId = (Session__GetCurrentProfileId(session) | 0), (id) => singleton_2.Delay(() => singleton_2.Bind(commonAuthService.DeleteRecord(profileId, id), (_arg) => singleton_2.Return(void 0)))), msg.fields[0], () => (new ExerciseRecord_Msg(4, [void 0])), (Item_4) => (new ExerciseRecord_Msg(2, [Item_4])))];
        case 4:
            return [new ExerciseRecord_Model(model.Id, model.Data, false, model.Error), Router_newUrl(new Route(42, [ExerciseRecord_Model__GetExerciseId(model)]))];
        case 2:
            return [new ExerciseRecord_Model(model.Id, model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new ExerciseRecord_Model(model.Id, model.Data, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(ExerciseRecord_service.GetExerciseRecord)(Session__GetCurrentProfileId(session)), msg.fields[0], (tupledArg) => (new ExerciseRecord_Msg(1, [tupledArg[0], tupledArg[1]])), (Item_2) => (new ExerciseRecord_Msg(2, [Item_2])))];
    }
}

function ExerciseRecord_displayProperty(data_, data__1) {
    const data = [data_, data__1];
    return columns(singleton(new Option(4, [])), ofArray([column(empty(), singleton(createElement("strong", {
        children: Interop_reactApi.Children.toArray([data[0]]),
    }))), column(empty(), singleton(data[1]))]));
}

function ExerciseRecord_displayProperties(data) {
    const properties = flex_form.getProperties(data);
    return map((tupledArg) => ExerciseRecord_displayProperty(tupledArg[0], tupledArg[1]), ofArray(zip(properties.Names, properties.Values)));
}

export function ExerciseRecord_view(session, model, _dispatch) {
    if ((model.Data != null) && !model.InProgress) {
        const patternInput = value_3(model.Data);
        const eventInfo = patternInput[0];
        const exerciseId = ExerciseRecord_Model__GetExerciseId(model);
        const children_8 = toList_1(delay(() => {
            let matchValue;
            return append_1((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display exercise record: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append_1(singleton_1(h2(empty())(singleton("Exercise record details"))), delay(() => append_1(singleton_1(list_4(empty(), ofArray([button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg) => {
                Router_modifyLocation(new Route(42, [exerciseId]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["View exercise"]),
            }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_1) => {
                Router_modifyLocation(new Route(46, [eventInfo.ContentTypeId, eventInfo.Id]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Edit exercise record"]),
            }))), button(ofArray([new Option_2(0, [new Color_IColor(6, [])]), new Option_2(16, [model.InProgress]), new Option_2(18, [(_arg_2) => {
                Router_modifyLocation(new Route(45, [eventInfo.ContentTypeId, eventInfo.Id, map_1(int64ToString, Session__GetActiveWorkoutId(session))]));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Add exercise record"]),
            })))]))), delay(() => {
                let children_6;
                return singleton_1((children_6 = append(ExerciseRecord_displayProperties(eventInfo), ExerciseRecord_displayProperties(patternInput[1])), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                })));
            }))))));
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        });
    }
    else {
        const children_10 = ofArray([h2(empty())(singleton("Exercise record")), "Loading data..."]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        });
    }
}

