import { PhysicalClientModule_$ctor } from "../../PhysicalClientModule.js";
import { Remoting_buildProxy_64DC51C } from "../../fable_modules/Fable.Remoting.Client.7.29.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../../fable_modules/Fable.Remoting.Client.7.29.0/Remoting.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.4.5.0/String.js";
import { UnifiedService_$reflection, UnifiedService_RouteBuilder, TemperatureRecord_$reflection, BodilyFunctionsRecord_$reflection, BloodPressureRecord_$reflection, RecordService$1_$reflection, WeightRecord_$reflection } from "../../Omnicv.Shared/PhysicalAPI.js";

export const physicalModule = PhysicalClientModule_$ctor();

export const weightService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/mod/phys/%s/%s"))("WeightRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(WeightRecord_$reflection()));

export const bloodPressureService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/mod/phys/%s/%s"))("BloodPressureRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(BloodPressureRecord_$reflection()));

export const bodilyFunctionsService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/mod/phys/%s/%s"))("BodilyFunctionsRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(BodilyFunctionsRecord_$reflection()));

export const temperatureService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, m) => toText(printf("/api/auth/mod/phys/%s/%s"))("TemperatureRecord")(m), RemotingModule_createApi()), RecordService$1_$reflection(TemperatureRecord_$reflection()));

export const unifiedService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(UnifiedService_RouteBuilder, RemotingModule_createApi()), UnifiedService_$reflection());

