import { Union } from "../../fable-library.4.5.0/Types.js";
import { list_type, class_type, string_type, union_type } from "../../fable-library.4.5.0/Reflection.js";
import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Screen_ToString_2D2414B4, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Screen_$reflection, Reflection_getCaseName } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.5.0/MapUtil.js";
import { contains } from "../../fable-library.4.5.0/List.js";
import { stringHash } from "../../fable-library.4.5.0/Util.js";
import { printf, toText } from "../../fable-library.4.5.0/String.js";
import { some } from "../../fable-library.4.5.0/Option.js";

export class ISize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "is-7", "is-8"];
    }
}

export function ISize_$reflection() {
    return union_type("Fulma.Columns.ISize", [], ISize, () => [[], [], [], [], [], [], [], []]);
}

export function ISize_ToString_2283FB3F(x) {
    return Reflection_getCaseName(x);
}

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-centered", "is-vcentered", "is-multiline", "is-gapless", "is-mobile", "is-desktop", "IsGap", "IsGapOnly", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Columns.Option", [], Option, () => [[], [], [], [], [], [], [["Item1", Screen_$reflection()], ["Item2", ISize_$reflection()]], [["Item1", Screen_$reflection()], ["Item2", ISize_$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="columns"></div>
 */
export function columns(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let screen_4, size_4, x_2, msg, arg, arg_1, arg_2, screen_5, size_5, x_3, msg_1, arg_3, arg_1_1, arg_2_1;
        switch (option.tag) {
            case 6: {
                const size = option.fields[1];
                const screen = option.fields[0];
                if (!contains("is-variable", result.Classes, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: stringHash,
                })) {
                    return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__AddClass_Z721C83C5(result, "is-variable"), ISize_ToString_2283FB3F(size) + Screen_ToString_2D2414B4(screen));
                }
                else {
                    return Common_GenericOptions__AddClass_Z721C83C5(result, ISize_ToString_2283FB3F(size) + Screen_ToString_2D2414B4(screen));
                }
            }
            case 7: {
                const size_3 = option.fields[1];
                const screen_3 = option.fields[0];
                if (!contains("is-variable", result.Classes, {
                    Equals: (x_1, y_1) => (x_1 === y_1),
                    GetHashCode: stringHash,
                })) {
                    return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__AddClass_Z721C83C5(result, "is-variable"), (screen_4 = screen_3, (size_4 = size_3, (screen_4.tag === 2) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : ((screen_4.tag === 1) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : ((screen_4.tag === 4) ? ((ISize_ToString_2283FB3F(size_4) + Screen_ToString_2D2414B4(screen_4)) + "-only") : ((x_2 = screen_4, (msg = ((arg = Screen_ToString_2D2414B4(x_2), (arg_1 = ISize_ToString_2283FB3F(size_4), (arg_2 = Screen_ToString_2D2414B4(x_2), toText(printf("Screen `%s` does not support `is-%s-%s-only`."))(arg)(arg_1)(arg_2))))), (console.warn(some(msg)), "")))))))));
                }
                else {
                    return Common_GenericOptions__AddClass_Z721C83C5(result, (screen_5 = screen_3, (size_5 = size_3, (screen_5.tag === 2) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : ((screen_5.tag === 1) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : ((screen_5.tag === 4) ? ((ISize_ToString_2283FB3F(size_5) + Screen_ToString_2D2414B4(screen_5)) + "-only") : ((x_3 = screen_5, (msg_1 = ((arg_3 = Screen_ToString_2D2414B4(x_3), (arg_1_1 = ISize_ToString_2283FB3F(size_5), (arg_2_1 = Screen_ToString_2D2414B4(x_3), toText(printf("Screen `%s` does not support `is-%s-%s-only`."))(arg_3)(arg_1_1)(arg_2_1))))), (console.warn(some(msg_1)), "")))))))));
                }
            }
            case 9:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 8:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 10:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "columns"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

