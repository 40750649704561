import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Modifier_IModifier_$reflection, Common_parseOptions, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7 } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.5.0/MapUtil.js";
import { Union } from "../../fable-library.4.5.0/Types.js";
import { union_type, lambda_type, unit_type, string_type, list_type, class_type, bool_type } from "../../fable-library.4.5.0/Reflection.js";
import { HTMLAttr, DOMAttr } from "../../Fable.React.9.3.0/Fable.React.Props.fs.js";

/**
 * Generate <aside class="menu"></aside>
 */
export function menu(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "menu"), (props, children_1) => react.createElement("aside", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <p class="menu-label"></p>
 */
export function label(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "menu-label"), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="menu-list"></div>
 */
export function list(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "menu-list"), (props, children_1) => react.createElement("ul", keyValueList(props, 1), ...children_1), children);
}

export class Item_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-active", "Props", "CustomClass", "OnClick", "Href", "Modifiers"];
    }
}

export function Item_Option_$reflection() {
    return union_type("Fulma.Menu.Item.Option", [], Item_Option, () => [[["Item", bool_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent", void 0), unit_type)]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

function Item_generateAnchor(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 3:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(40, [option.fields[0]]));
            case 4:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(94, [option.fields[0]]));
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 5:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }), (props_1, children_1) => react.createElement("a", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <li><a></a></li>
 * You control the `a` element
 */
export function Item_li(options, children) {
    const children_1 = [Item_generateAnchor(options, children)];
    return react.createElement("li", {}, ...children_1);
}

/**
 * Generate <a></a>
 */
export function Item_a(options, children) {
    return Item_generateAnchor(options, children);
}

