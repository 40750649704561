import { Union } from "../../fable-library.4.5.0/Types.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Common_GenericOptions__AddCaseName_1505, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Size_ISize_$reflection, Color_IColor_$reflection } from "../Common.fs.js";
import { union_type, string_type, lambda_type, unit_type, list_type, class_type, bool_type } from "../../fable-library.4.5.0/Reflection.js";
import { Reflection_getCaseName } from "../Common.fs.js";
import { DOMAttr, HTMLAttr } from "../../Fable.React.9.3.0/Fable.React.Props.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.5.0/MapUtil.js";
import { singleton, empty, cons, map, exists } from "../../fable-library.4.5.0/List.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Color", "Size", "is-fullwidth", "is-link", "is-outlined", "is-inverted", "is-text", "is-ghost", "is-rounded", "is-expanded", "is-hovered", "is-focused", "is-active", "is-loading", "is-static", "is-light", "Disabled", "Props", "OnClick", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Button.Option", [], Option, () => [[["Item", Color_IColor_$reflection()]], [["Item", Size_ISize_$reflection()]], [], [], [], [], [], [], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", bool_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent", void 0), unit_type)]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export function btnView(element, options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, state;
        switch (option.tag) {
            case 1: {
                matchResult = 1;
                break;
            }
            case 3:
            case 2:
            case 4:
            case 5:
            case 6:
            case 8:
            case 9:
            case 7:
            case 15: {
                matchResult = 2;
                break;
            }
            case 16: {
                matchResult = 4;
                break;
            }
            case 18: {
                matchResult = 5;
                break;
            }
            case 17: {
                matchResult = 6;
                break;
            }
            case 19: {
                matchResult = 7;
                break;
            }
            case 20: {
                matchResult = 8;
                break;
            }
            case 10: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 11: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 12: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 13: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 14: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 2:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 3:
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            case 4:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(79, [option.fields[0]]));
            case 5:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(40, [option.fields[0]]));
            case 6:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
        }
    }, "button"), element, children);
}

/**
 * Generate <button class="button"></button>
 */
export function button(options, children) {
    return btnView((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <span class="button"></span>
 */
export function span(options, children) {
    return btnView((props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), options, children);
}

/**
 * Generate <a class="button"></a>
 */
export function a(options, children) {
    return btnView((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Input_btnInput(typ, options) {
    if (exists((opts) => {
        if (opts.tag === 17) {
            return true;
        }
        else {
            return false;
        }
    }, options)) {
        return btnView((options_1, _arg) => react.createElement("input", keyValueList(options_1, 1)), map((opts_1) => {
            if (opts_1.tag === 17) {
                return new Option(17, [cons(new HTMLAttr(159, [typ]), opts_1.fields[0])]);
            }
            else {
                return opts_1;
            }
        }, options), empty());
    }
    else {
        return btnView((options_2, _arg_1) => react.createElement("input", keyValueList(options_2, 1)), cons(new Option(17, [singleton(new HTMLAttr(159, [typ]))]), options), empty());
    }
}

/**
 * Generate <input type="reset" class="button" />
 */
export function Input_reset(options) {
    return Input_btnInput("reset", options);
}

/**
 * Generate <input type="submit" class="button" />
 */
export function Input_submit(options) {
    return Input_btnInput("submit", options);
}

export class List_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["has-addons", "is-centered", "is-right", "are-small", "are-medium", "are-large", "Props", "CustomClass", "Modifiers"];
    }
}

export function List_Option_$reflection() {
    return union_type("Fulma.Button.List.Option", [], List_Option, () => [[], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="buttons"></div>
 */
export function list(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 6:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 8:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "buttons"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

