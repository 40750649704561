import { Union } from "../../fable-library.4.5.0/Types.js";
import { Common_parseOptions, Color_ofColor, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Size_ISize_$reflection, Color_IColor_$reflection } from "../Common.fs.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library.4.5.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.5.0/MapUtil.js";
import { Reflection_getCaseName } from "../Common.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Color", "Size", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Message.Option", [], Option, () => [[["Item", Color_IColor_$reflection()]], [["Item", Size_ISize_$reflection()]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <article class="message"></article>
 */
export function message(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 2:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 4:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
        }
    }, "message"), (props_1, children_1) => react.createElement("article", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="message-header"></div>
 */
export function header(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "message-header"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="message-body"></div>
 */
export function body(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "message-body"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

