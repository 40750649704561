import { Union } from "../fable-library.4.5.0/Types.js";
import { Common_GenericOption, TextWeight_Option, Modifier_IModifier, Color_IColor, Text_span, Common_GenericOptions__ToReactElement_4509C2D7, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_Z2B8EEFE, Color_ofColor, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddClass_Z721C83C5, Size_ISize_$reflection, Color_IColor_$reflection } from "../Fulma.3.0.0/Common.fs.js";
import { union_type, string_type, lambda_type, unit_type, list_type, class_type, bool_type } from "../fable-library.4.5.0/Reflection.js";
import { Reflection_getCaseName } from "../Fulma.3.0.0/Common.fs.js";
import { DOMAttr, HTMLAttr } from "../Fable.React.9.3.0/Fable.React.Props.fs.js";
import { empty, ofArray, singleton, tryPick } from "../fable-library.4.5.0/List.js";
import * as react from "react";
import { keyValueList } from "../fable-library.4.5.0/MapUtil.js";
import { div } from "../Fulma.3.0.0/Elements/Form/Field.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Color", "Size", "is-rounded", "is-outlined", "is-thin", "is-rtl", "Checked", "Disabled", "LabelProps", "InputProps", "OnChange", "CustomClass", "Id"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Extensions.Wikiki.Switch.Option", [], Option, () => [[["Item", Color_IColor_$reflection()]], [["Item", Size_ISize_$reflection()]], [], [], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", lambda_type(class_type("Browser.Types.Event", void 0), unit_type)]], [["Item", string_type]], [["Item", string_type]]]);
}

function parseOptionsForInput(result, option) {
    switch (option.tag) {
        case 1:
            return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
        case 3:
        case 2:
        case 5:
        case 4:
            return Common_GenericOptions__AddCaseName_1505(result, option);
        case 6:
            return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(62, [option.fields[0]]));
        case 7:
            return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(79, [option.fields[0]]));
        case 9:
            return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
        case 11:
            return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
        case 10:
            return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(9, [option.fields[0]]));
        case 12:
            return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(99, [option.fields[0]]));
        case 8:
            return result;
        default:
            return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
    }
}

function parseOptionsForLabel(result, option) {
    switch (option.tag) {
        case 12:
            return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(96, [option.fields[0]]));
        case 8:
            return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
        default:
            return result;
    }
}

function hasId(options) {
    return tryPick((option) => {
        if (option.tag === 12) {
            return true;
        }
        else {
            return void 0;
        }
    }, options) != null;
}

/**
 * Generate
 * <fragment>
 * <input class="switch" type="checkbox">
 * <label>One</label>
 * </fragment>
 */
export function switchInline(options, children) {
    if (hasId(options)) {
        const inputElement = Common_GenericOptions__ToReactElement_Z2B8EEFE(Common_GenericOptions_Parse_Z4D1A7726(options, parseOptionsForInput, "switch", singleton(new HTMLAttr(159, ["checkbox"]))), (props) => react.createElement("input", keyValueList(props, 1)));
        const labelElement = Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, parseOptionsForLabel), (props_3, children_1) => react.createElement("label", keyValueList(props_3, 1), ...children_1), children);
        return react.createElement(react.Fragment, {}, inputElement, labelElement);
    }
    else {
        return Text_span(singleton(new Common_GenericOption(2, [ofArray([new Modifier_IModifier(1, [new Color_IColor(8, [])]), new Modifier_IModifier(2, [new TextWeight_Option(3, [])])])])), singleton("You need to set an Id value for your Switch "));
    }
}

/**
 * Generate
 * <div class="field">
 * <fragment>
 * <input class="switch" type="checkbox">
 * <label>One</label>
 * </fragment>
 * </div>
 */
export function switch$(options, children) {
    return div(empty(), singleton(switchInline(options, children)));
}

