import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.29.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.29.0/Remoting.fs.js";
import { CommonAuthService_$reflection, CommonAuthService_RouteBuilder, AnonymousService_$reflection, AnonymousService_RouteBuilder } from "../Omnicv.Shared/API.js";
import { ProxyRequestException__get_Response, ProxyRequestException__get_StatusCode, ProxyRequestException } from "../fable_modules/Fable.Remoting.Client.7.29.0/Types.fs.js";
import { ServerError_$reflection, ServerError } from "../Omnicv.Shared.Common/Errors.js";
import { SimpleJson_tryParse } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { anonRecord_type } from "../fable_modules/fable-library.4.5.0/Reflection.js";

export const anonService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(AnonymousService_RouteBuilder, RemotingModule_createApi()), AnonymousService_$reflection());

export const commonAuthService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(CommonAuthService_RouteBuilder, RemotingModule_createApi()), CommonAuthService_$reflection());

export function exnToError(e) {
    let matchValue;
    if (e instanceof ProxyRequestException) {
        const ex = e;
        try {
            return ((matchValue = SimpleJson_tryParse(ProxyRequestException__get_Response(ex).ResponseBody), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["error", ServerError_$reflection()]))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())).error;
        }
        catch (matchValue_1) {
            return (ProxyRequestException__get_StatusCode(ex) === 401) ? (new ServerError(1, [ProxyRequestException__get_Response(ex).ResponseBody])) : (new ServerError(0, [e.message]));
        }
    }
    else {
        return new ServerError(0, [e.message]);
    }
}

