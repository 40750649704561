import { Record } from "../fable-library.4.5.0/Types.js";
import { record_type, string_type } from "../fable-library.4.5.0/Reflection.js";
import { field } from "../Fable.Form.2.0.0/Base.fs.js";

export class Attributes extends Record {
    constructor(Text$) {
        super();
        this.Text = Text$;
    }
}

export function Attributes_$reflection() {
    return record_type("Fable.Form.Simple.Field.CheckboxField.Attributes", [], Attributes, () => [["Text", string_type]]);
}

export function form() {
    return (build) => ((config) => field((_arg) => false, build, config));
}

