import { PageBoundary, PagingOptions_$reflection, EventInfo_$reflection, PagingOptions } from "../../../Omnicv.Shared.Common/CoreEventInfo.js";
import { Record, Union } from "../../../fable_modules/fable-library.4.5.0/Types.js";
import { class_type, record_type, int32_type, bool_type, list_type, tuple_type, union_type, string_type } from "../../../fable_modules/fable-library.4.5.0/Reflection.js";
import { UnifiedRecord_$reflection } from "../../Omnicv.Diary.Shared/DiaryAPI.js";
import { curry3 } from "../../../fable_modules/fable-library.4.5.0/Util.js";
import { unifiedService } from "../Server.js";
import { isEmpty, ofArray, singleton as singleton_1, last as last_1, empty, length } from "../../../fable_modules/fable-library.4.5.0/List.js";
import { View_ViewConfig$2, View_Validation, View_Action$1, succeed, textField, append, View_Model$1, View_State, View_idle, View_Model$1_$reflection } from "../../../fable_modules/Fable.Form.Simple.4.0.0/Form.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Icons, Session__GetCurrentProfileId } from "../../../Omnicv.Client.Common/SharedView.js";
import { exnToDisplayString } from "../../../Omnicv.Client.Common/Utils.js";
import { Modifiers_ensureValidTrimmed } from "../../../Omnicv.Shared.Common/Validation.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.4.5.0/Choice.js";
import { Attributes$1 } from "../../../fable_modules/Fable.Form.Simple.4.0.0/TextField.fs.js";
import { FieldConfig$4 } from "../../../fable_modules/Fable.Form.2.0.0/Base.fs.js";
import { asHtml } from "../../../fable_modules/Fable.Form.Simple.Bulma.4.0.0/Form.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../../../fable_modules/fable-library.4.5.0/Seq.js";
import { Option, h3, h1 } from "../../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Option as Option_1, button, list } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { icon } from "../../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../../Omnicv.Client.Common/SharedView.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { defaultOf } from "../../../fable_modules/fable-library.4.5.0/Util.js";
import { displayUnifiedEntries } from "../DiaryClientUtils.js";

const pageSize = 30;

const initPagingOptions = new PagingOptions(void 0, pageSize, false, void 0, void 0);

export class SearchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ByTags", "General"];
    }
}

export function SearchType_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchType", [], SearchType, () => [[["Item", string_type]], []]);
}

export class SearchData extends Record {
    constructor(SearchType, SearchQuery, SearchResults, IsLoading, PagingOptions, TotalCount) {
        super();
        this.SearchType = SearchType;
        this.SearchQuery = SearchQuery;
        this.SearchResults = SearchResults;
        this.IsLoading = IsLoading;
        this.PagingOptions = PagingOptions;
        this.TotalCount = (TotalCount | 0);
    }
}

export function SearchData_$reflection() {
    return record_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchData", [], SearchData, () => [["SearchType", SearchType_$reflection()], ["SearchQuery", string_type], ["SearchResults", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))], ["IsLoading", bool_type], ["PagingOptions", PagingOptions_$reflection()], ["TotalCount", int32_type]]);
}

export function SearchData__FetchFunc_Z524259A4(this$, profileId) {
    const matchValue = this$.SearchType;
    if (matchValue.tag === 0) {
        return curry3(unifiedService.SearchByTags)(profileId)(matchValue.fields[0]);
    }
    else {
        return curry3(unifiedService.SearchGeneral)(profileId)(this$.SearchQuery);
    }
}

export function SearchData__CanLoadMore(this$) {
    if (length(this$.SearchResults) > 0) {
        return length(this$.SearchResults) === this$.PagingOptions.PageSize;
    }
    else {
        return false;
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FormChanged", "Submit", "Reload", "LoadMore", "Success", "SuccessMore", "Failure"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.Msg", [], Msg, () => [[["Item", View_Model$1_$reflection(SearchData_$reflection())]], [["Item", string_type]], [], [], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", class_type("System.Exception")]]]);
}

export function init(session, searchType) {
    let temp;
    if (searchType.tag === 1) {
        return [View_idle(new SearchData(searchType, "", empty(), false, initPagingOptions, 0)), Cmd_none()];
    }
    else {
        const model = new SearchData(searchType, "", empty(), true, initPagingOptions, 0);
        return [(temp = View_idle(model), new View_Model$1(temp.Values, new View_State(3, ["Search done on load"]), temp.ErrorTracking)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, SearchData__FetchFunc_Z524259A4(model, Session__GetCurrentProfileId(session)), model.PagingOptions, (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
    }
}

export function update(session, msg, model) {
    let inputRecord_3, inputRecord_4, inputRecord_5;
    const processSuccess = (finalRecords) => {
        let inputRecord;
        const totalCount = (model.Values.TotalCount + length(finalRecords)) | 0;
        return [new View_Model$1((inputRecord = model.Values, new SearchData(inputRecord.SearchType, inputRecord.SearchQuery, finalRecords, false, inputRecord.PagingOptions, totalCount)), new View_State(3, [`Search finished successfully, last ${length(finalRecords)} of ${totalCount} records loaded`]), model.ErrorTracking), Cmd_none()];
    };
    switch (msg.tag) {
        case 1: {
            let updatedModel;
            const inputRecord_1 = model.Values;
            updatedModel = (new SearchData(inputRecord_1.SearchType, msg.fields[0], inputRecord_1.SearchResults, true, inputRecord_1.PagingOptions, 0));
            return [new View_Model$1(updatedModel, new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, SearchData__FetchFunc_Z524259A4(updatedModel, Session__GetCurrentProfileId(session)), model.Values.PagingOptions, (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
        }
        case 3: {
            const profileId_1 = Session__GetCurrentProfileId(session) | 0;
            const last = last_1(model.Values.SearchResults)[0];
            let pagingOptions;
            const inputRecord_2 = model.Values.PagingOptions;
            pagingOptions = (new PagingOptions(new PageBoundary(last.Id, last.EventTimestamp), inputRecord_2.PageSize, inputRecord_2.Ascending, inputRecord_2.From, inputRecord_2.To));
            return [new View_Model$1((inputRecord_3 = model.Values, new SearchData(inputRecord_3.SearchType, inputRecord_3.SearchQuery, inputRecord_3.SearchResults, true, pagingOptions, inputRecord_3.TotalCount)), new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, SearchData__FetchFunc_Z524259A4(model.Values, profileId_1), pagingOptions, (Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(6, [Item_3])))];
        }
        case 2:
            return [new View_Model$1((inputRecord_4 = model.Values, new SearchData(inputRecord_4.SearchType, inputRecord_4.SearchQuery, inputRecord_4.SearchResults, true, initPagingOptions, 0)), new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, SearchData__FetchFunc_Z524259A4(model.Values, Session__GetCurrentProfileId(session)), initPagingOptions, (Item_4) => (new Msg(4, [Item_4])), (Item_5) => (new Msg(6, [Item_5])))];
        case 4:
            return processSuccess(msg.fields[0]);
        case 5:
            return processSuccess(msg.fields[0]);
        case 6:
            return [new View_Model$1((inputRecord_5 = model.Values, new SearchData(inputRecord_5.SearchType, inputRecord_5.SearchQuery, empty(), false, inputRecord_5.PagingOptions, inputRecord_5.TotalCount)), new View_State(2, [exnToDisplayString(msg.fields[0])]), model.ErrorTracking), Cmd_none()];
        default:
            return [msg.fields[0], Cmd_none()];
    }
}

const form = append(textField(new FieldConfig$4((value) => (new FSharpResult$2(0, [Modifiers_ensureValidTrimmed(value)])), (values) => values.SearchQuery, (newValue, values_1) => (new SearchData(values_1.SearchType, newValue, values_1.SearchResults, values_1.IsLoading, values_1.PagingOptions, values_1.TotalCount)), (values_2) => ((values_2.SearchQuery.trim().length < 3) ? "Search query too short" : void 0), new Attributes$1("Search query", "Search something here", empty()))), succeed((Item) => (new Msg(1, [Item]))));

function viewForm(model, dispatch) {
    return asHtml(new View_ViewConfig$2(dispatch, (Item) => (new Msg(0, [Item])), new View_Action$1(0, ["Search diary"]), new View_Validation(1, [])))(form)(model);
}

export function view(model, dispatch) {
    const elms = toList(delay(() => {
        if (model.State.tag === 3) {
            const values = model.Values;
            return append_1(singleton(h1(empty())(singleton_1("Search results"))), delay(() => append_1(singleton(h3(singleton_1(new Option(6, [])))(singleton_1(`${length(values.SearchResults)} of ${values.TotalCount} records loaded`))), delay(() => append_1(singleton(list(empty(), toList(delay(() => append_1(SearchData__CanLoadMore(model.Values) ? singleton(button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(16, [model.Values.IsLoading]), new Option_1(18, [(_arg) => {
                dispatch(new Msg(3, []));
            }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(7, []))), []))), createElement("span", {
                children: Interop_reactApi.Children.toArray(["Load more"]),
            })]))) : empty_1(), delay(() => (!isEmpty(model.Values.SearchResults) ? singleton(button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [model.Values.IsLoading]), new Option_1(18, [(_arg_1) => {
                dispatch(new Msg(2, []));
            }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(5, []))), []))), createElement("span", {
                children: Interop_reactApi.Children.toArray(["Reload"]),
            })]))) : empty_1()))))))), delay(() => append_1((model.Values.SearchType.tag === 1) ? singleton(viewForm(model, dispatch)) : singleton(defaultOf()), delay(() => {
                let children_4;
                return singleton((children_4 = displayUnifiedEntries(model.Values.SearchResults, model.Values.IsLoading, (value) => {
                }), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                })));
            }))))))));
        }
        else {
            return append_1(singleton(h1(empty())(singleton_1("Search the diary"))), delay(() => singleton(viewForm(model, dispatch))));
        }
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

