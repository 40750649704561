import * as flex_form from "../../../src/javascript/flex-form.js";
import { format, substring, isNullOrWhiteSpace, replace } from "../../fable_modules/fable-library.4.5.0/String.js";
import { Record, toString, FSharpRef, Union } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { record_type, lambda_type, tuple_type, uint8_type, bool_type, float64_type, int32_type, option_type, class_type, union_type, string_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { FlexWrap_Option, TextTransform_Option, TextWeight_Option, TextSize_Option, Text_span, Common_GenericOption, Modifier_IModifier, Size_ISize, Screen, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { safeHash, createObj, compare, comparePrimitives, int32ToString, curry2, uncurry2, defaultOf as defaultOf_1, equals } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { collect, map as map_2, head, empty as empty_1, singleton, append, delay, toList, fold } from "../../fable_modules/fable-library.4.5.0/Seq.js";
import { some, map, defaultArg, value as value_18, unwrap } from "../../fable_modules/fable-library.4.5.0/Option.js";
import { sortBy, ofSeq as ofSeq_1, filter, exists, map as map_1, isEmpty, ofArray, append as append_1, cons, singleton as singleton_1, empty } from "../../fable_modules/fable-library.4.5.0/List.js";
import { body, label as label_2, Option as Option_10, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { Option, label as label_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Label.fs.js";
import { Option as Option_1, div as div_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { Option as Option_2, help as help_2 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Help.fs.js";
import { defaultOf } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { Option as Option_3, textarea } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Textarea.fs.js";
import { input as input_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_4, IInputType } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { column, Option as Option_5, ISize } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { list as list_12, Option as Option_6, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Option as Option_7, icon as icon_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Option as Option_8, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { ofSeq, empty as empty_2, contains, FSharpSet__get_Count } from "../../fable_modules/fable-library.4.5.0/Set.js";
import { tryParse } from "../../fable_modules/fable-library.4.5.0/Int32.js";
import { FSharpChoice$2, Result_Map, FSharpResult$2 } from "../../fable_modules/fable-library.4.5.0/Choice.js";
import { tryParse as tryParse_1 } from "../../fable_modules/fable-library.4.5.0/Double.js";
import { second, minute, hour, parse, day, month, year, create, now as now_1, toUniversalTime, tryParse as tryParse_2, minValue, toLocalTime, toString as toString_1 } from "../../fable_modules/fable-library.4.5.0/Date.js";
import { stringToEnum, valuesToSelectOptions, splitSeconds } from "../Utils.js";
import { DOMAttr, HTMLAttr } from "../../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { Config_placeLookupId } from "../Extensions.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Option as Option_9, select } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Select.fs.js";
import { keyValueList } from "../../fable_modules/fable-library.4.5.0/MapUtil.js";
import { Option as Option_11, radioInline } from "../../fable_modules/Fulma.Extensions.Wikiki.Checkradio.3.0.0/Checkradio.fs.js";
import { input as input_2, checkbox } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Checkbox.fs.js";
import { concatToServer, toClientListView } from "../../Omnicv.Shared.Common/TagUtils.js";
import { singleton as singleton_2 } from "../../fable_modules/fable-library.4.5.0/AsyncBuilder.js";
import { TagsInputComponent_input } from "../../fable_modules/Feliz.Bulma.TagsInput.3.0.0/Input.fs.js";
import { RatingStarsProps, ratingStars } from "./RatingStars.js";
import { Option as Option_12, container } from "../../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { EventInfo__GetLocationTz, EventInfo__GetClientTz, EventInfo__DisplayTimeZone } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import * as common from "../../../src/javascript/common.js";
import { StringBuilder__get_Length, StringBuilder__Append_Z721C83C5, StringBuilder_$ctor } from "../../fable_modules/fable-library.4.5.0/System.Text.js";
import { eventInfoCoordinatesToPositionOrDefault, triggerUpdateLocation } from "./EventInfoManager.js";
import { List_distinct } from "../../fable_modules/fable-library.4.5.0/Seq2.js";
import { LocationDescriptor, distanceInMetersBetweenPositions } from "../MapLiteral.js";

function convert(object) {
    return object;
}

function objectFieldGetter(fieldName, object) {
    return convert(flex_form.objectFieldGetter(fieldName, object));
}

function objectFieldSetter(fieldName, object, newValue) {
    return convert(flex_form.objectFieldSetter(fieldName, object, newValue));
}

export function setWorkoutRecordId(record, newId) {
    return objectFieldSetter("WorkoutRecordId", record, newId);
}

export function setId(record, newId) {
    return objectFieldSetter("Id", record, newId);
}

export function setProfileId(record, profileId) {
    return objectFieldSetter("ProfileId", record, profileId);
}

export function clearId(record) {
    return setId(record, 0n);
}

function slugify(name) {
    return replace(name, " ", "-");
}

export class FieldValidationResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ok", "Warning", "Error"];
    }
}

export function FieldValidationResult_$reflection() {
    return union_type("Omnicv.Client.Common.Components.FlexForm.FieldValidationResult", [], FieldValidationResult, () => [[], [["Item", string_type]], [["Item", string_type]]]);
}

export function FieldValidationResult__ToColour_Z5463E381(this$, defaultColour) {
    switch (this$.tag) {
        case 1:
            return new Color_IColor(7, []);
        case 2:
            return new Color_IColor(8, []);
        default:
            return defaultColour;
    }
}

export function FieldValidationResult__IsValid(this$) {
    return equals(this$, new FieldValidationResult(0, []));
}

export function FieldValidationResult__IsErrorResult(this$) {
    if (this$.tag === 2) {
        return true;
    }
    else {
        return false;
    }
}

export function FieldValidationResult__Merge_30561432(this$, other) {
    let matchResult, right, left, msg1, msg2, msg1_1, msg2_1, msg1_2, msg2_2, msg1_3, msg2_3;
    switch (this$.tag) {
        case 2: {
            switch (other.tag) {
                case 2: {
                    matchResult = 2;
                    msg1 = this$.fields[0];
                    msg2 = other.fields[0];
                    break;
                }
                case 1: {
                    matchResult = 3;
                    msg1_1 = this$.fields[0];
                    msg2_1 = other.fields[0];
                    break;
                }
                default: {
                    matchResult = 1;
                    left = this$;
                }
            }
            break;
        }
        case 1: {
            switch (other.tag) {
                case 2: {
                    matchResult = 4;
                    msg1_2 = this$.fields[0];
                    msg2_2 = other.fields[0];
                    break;
                }
                case 1: {
                    matchResult = 5;
                    msg1_3 = this$.fields[0];
                    msg2_3 = other.fields[0];
                    break;
                }
                default: {
                    matchResult = 1;
                    left = this$;
                }
            }
            break;
        }
        default: {
            matchResult = 0;
            right = other;
        }
    }
    switch (matchResult) {
        case 0:
            return right;
        case 1:
            return left;
        case 2:
            return new FieldValidationResult(2, [(msg1 + "; ") + msg2]);
        case 3:
            return new FieldValidationResult(2, [(msg1_1 + "; ") + msg2_1]);
        case 4:
            return new FieldValidationResult(2, [(msg1_2 + "; ") + msg2_2]);
        default:
            return new FieldValidationResult(1, [(msg1_3 + "; ") + msg2_3]);
    }
}

function mergeResults(results) {
    return fold((state, result) => FieldValidationResult__Merge_30561432(result, state), new FieldValidationResult(0, []), results);
}

export class CustomField$1 {
    constructor(name, customRender) {
        this.name = name;
        this.customRender = customRender;
        this["Details@"] = void 0;
        this["Help@"] = void 0;
        this["IsRequired@"] = true;
    }
    get Details() {
        const __ = this;
        return unwrap(__["Details@"]);
    }
    set Details(v) {
        const __ = this;
        __["Details@"] = v;
    }
    get Help() {
        const __ = this;
        return unwrap(__["Help@"]);
    }
    set Help(v) {
        const __ = this;
        __["Help@"] = v;
    }
    get IsRequired() {
        const __ = this;
        return __["IsRequired@"];
    }
    set IsRequired(v) {
        const __ = this;
        __["IsRequired@"] = v;
    }
    get Name() {
        const this$ = this;
        return this$.name;
    }
    Render(isDisabled, value) {
        const this$ = this;
        return this$.customRender(isDisabled, value);
    }
    OnChange(value) {
    }
    Validate(_value) {
        return new FieldValidationResult(0, []);
    }
}

export function CustomField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.CustomField`1", [gen0], CustomField$1);
}

export function CustomField$1_$ctor_693A43B3(name, customRender) {
    return new CustomField$1(name, customRender);
}

export class BaseField$1 {
    constructor(name) {
        this.name = name;
        this["Details@"] = void 0;
        this["Help@"] = void 0;
        this["IsRequired@"] = true;
    }
    get Details() {
        const __ = this;
        return unwrap(__["Details@"]);
    }
    set Details(v) {
        const __ = this;
        __["Details@"] = v;
    }
    get Help() {
        const __ = this;
        return unwrap(__["Help@"]);
    }
    set Help(v) {
        const __ = this;
        __["Help@"] = v;
    }
    get IsRequired() {
        const __ = this;
        return __["IsRequired@"];
    }
    set IsRequired(v) {
        const __ = this;
        __["IsRequired@"] = v;
    }
    get Name() {
        const this$ = this;
        return this$.name;
    }
    Render(isDisabled, value) {
        const this$ = this;
        return this$["Omnicv.Client.Common.Components.FlexForm.BaseField`1.Render"](isDisabled, value);
    }
    OnChange(value) {
        const this$ = this;
        this$["Omnicv.Client.Common.Components.FlexForm.BaseField`1.OnChange2B595"](value);
    }
    Validate(value) {
        const this$ = this;
        return this$["Omnicv.Client.Common.Components.FlexForm.BaseField`1.Validate2B595"](value);
    }
}

export function BaseField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.BaseField`1", [gen0], BaseField$1);
}

export function BaseField$1_$ctor_Z721C83C5(name) {
    return new BaseField$1(name);
}

export class GetterSetterBaseField$2 extends BaseField$1 {
    constructor(name, getter, setter, validate, onChange) {
        super(name);
        this.name_1 = name;
        this.getter = getter;
        this.setter = setter;
        this.validate = validate;
        this.onChange = onChange;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.ControlModifiers"() {
        return empty();
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.ControlExtras2B595"(_arg) {
        return empty();
    }
    "Omnicv.Client.Common.Components.FlexForm.BaseField`1.Render"(isDisabled, object) {
        const this$ = this;
        const value = this$.getter(object);
        const error = GetterSetterBaseField$2__get_Field(this$).Validate(object);
        return div(empty(), toList(delay(() => append(singleton(label_1(singleton_1(new Option(2, ["is-normal"])), singleton_1(GetterSetterBaseField$2__get_Field(this$).Name))), delay(() => {
            let temp;
            return append(singleton(div_1((temp = this$["Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.ControlModifiers"](), isDisabled ? cons(new Option_1(2, [true]), temp) : temp), append_1(singleton_1(this$["Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"](isDisabled, error, object, value)), this$["Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.ControlExtras2B595"](object)))), delay(() => append((GetterSetterBaseField$2__get_Field(this$).Help != null) ? singleton(help_2(singleton_1(new Option_2(2, [new Color_IColor(6, [])])), singleton_1(value_18(GetterSetterBaseField$2__get_Field(this$).Help)))) : empty_1(), delay(() => {
                const matchValue = error;
                return (matchValue.tag === 1) ? singleton(help_2(singleton_1(new Option_2(2, [new Color_IColor(7, [])])), singleton_1(matchValue.fields[0]))) : ((matchValue.tag === 0) ? singleton(defaultOf()) : singleton(help_2(singleton_1(new Option_2(2, [new Color_IColor(8, [])])), singleton_1(matchValue.fields[0]))));
            }))));
        })))));
    }
    "Omnicv.Client.Common.Components.FlexForm.BaseField`1.OnChange2B595"(object) {
        const this$ = this;
        this$.onChange(object);
    }
    "Omnicv.Client.Common.Components.FlexForm.BaseField`1.Validate2B595"(object) {
        const this$ = this;
        const result = this$.validate(GetterSetterBaseField$2__GetValue_2B595(this$, object));
        return (result == null) ? (new FieldValidationResult(0, [])) : (new FieldValidationResult(2, [value_18(result)]));
    }
}

export function GetterSetterBaseField$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2", [gen0, gen1], GetterSetterBaseField$2, BaseField$1_$reflection(gen0));
}

export function GetterSetterBaseField$2_$ctor_4CAD41BD(name, getter, setter, validate, onChange) {
    return new GetterSetterBaseField$2(name, getter, setter, validate, onChange);
}

export function GetterSetterBaseField$2__CallOnChange(this$, object, newValue) {
    const newObject = this$.setter(object, newValue);
    this$["Omnicv.Client.Common.Components.FlexForm.BaseField`1.OnChange2B595"](newObject);
}

export function GetterSetterBaseField$2__get_Field(this$) {
    return this$;
}

export function GetterSetterBaseField$2__get_Placeholder(this$) {
    return defaultArg(GetterSetterBaseField$2__get_Field(this$).Details, this$.name_1);
}

export function GetterSetterBaseField$2__GetValue_2B595(this$, object) {
    return this$.getter(object);
}

export class JsGetterSetterBaseField$2 extends GetterSetterBaseField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, (object) => objectFieldGetter(fieldName, object), (object_1, newValue) => objectFieldSetter(fieldName, object_1, newValue), validate, onChange);
    }
}

export function JsGetterSetterBaseField$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.JsGetterSetterBaseField`2", [gen0, gen1], JsGetterSetterBaseField$2, GetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function JsGetterSetterBaseField$2_$ctor_3195DDE5(name, fieldName, validate, onChange) {
    return new JsGetterSetterBaseField$2(name, fieldName, validate, onChange);
}

export class TextFieldBase$2 extends JsGetterSetterBaseField$2 {
    constructor(isTextArea, name, fieldName, fromValue, toValue, validate, onChange) {
        super(name, fieldName, validate, onChange);
        this.isTextArea = isTextArea;
        this.fromValue = fromValue;
        this.toValue = toValue;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, value) {
        const this$ = this;
        return this$.isTextArea ? textarea(ofArray([new Option_3(7, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_3(13, [GetterSetterBaseField$2__get_Placeholder(this$)]), new Option_3(10, [this$.fromValue(value)]), new Option_3(15, [(event) => {
            TextFieldBase$2__ProcessOnChange(this$, object, event);
        }]), new Option_3(9, [isDisabled])]), empty()) : input_1(ofArray([new Option_4(1, [new IInputType(0, [])]), new Option_4(2, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_4(12, [GetterSetterBaseField$2__get_Placeholder(this$)]), new Option_4(8, [this$.fromValue(value)]), new Option_4(13, [(event_1) => {
            TextFieldBase$2__ProcessOnChange(this$, object, event_1);
        }]), new Option_4(4, [isDisabled])]));
    }
}

export function TextFieldBase$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TextFieldBase`2", [gen0, gen1], TextFieldBase$2, JsGetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function TextFieldBase$2_$ctor_Z52490B1(isTextArea, name, fieldName, fromValue, toValue, validate, onChange) {
    return new TextFieldBase$2(isTextArea, name, fieldName, fromValue, toValue, validate, onChange);
}

function TextFieldBase$2__ProcessOnChange(this$, object, event) {
    let elem, elem_1;
    GetterSetterBaseField$2__CallOnChange(this$, object, this$.toValue(this$.isTextArea ? ((elem = event.target, elem.value)) : ((elem_1 = event.target, elem_1.value))));
}

export class TextField$1 extends TextFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(false, name, fieldName, (x) => x, (x_1) => x_1, validate, onChange);
    }
}

export function TextField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TextField`1", [gen0], TextField$1, TextFieldBase$2_$reflection(gen0, string_type));
}

export function TextField$1_$ctor_7EE3C92A(name, fieldName, validate, onChange) {
    return new TextField$1(name, fieldName, validate, onChange);
}

export class TextAreaField$1 extends TextFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(true, name, fieldName, (x) => x, (x_1) => x_1, validate, onChange);
    }
}

export function TextAreaField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TextAreaField`1", [gen0], TextAreaField$1, TextFieldBase$2_$reflection(gen0, string_type));
}

export function TextAreaField$1_$ctor_7EE3C92A(name, fieldName, validate, onChange) {
    return new TextAreaField$1(name, fieldName, validate, onChange);
}

function stringToOpt(s) {
    if (isNullOrWhiteSpace(s)) {
        return void 0;
    }
    else {
        return s;
    }
}

export class TextFieldOpt$1 extends TextFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(false, name, fieldName, (option) => defaultArg(option, ""), stringToOpt, validate, onChange);
        const this$ = new FSharpRef(defaultOf_1());
        this$.contents = this;
        this["init@274"] = 1;
        GetterSetterBaseField$2__get_Field(this$.contents).IsRequired = false;
    }
}

export function TextFieldOpt$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TextFieldOpt`1", [gen0], TextFieldOpt$1, TextFieldBase$2_$reflection(gen0, option_type(string_type)));
}

export function TextFieldOpt$1_$ctor_773DF4C9(name, fieldName, validate, onChange) {
    return new TextFieldOpt$1(name, fieldName, validate, onChange);
}

export class TextAreaFieldOpt$1 extends TextFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(true, name, fieldName, (option) => defaultArg(option, ""), stringToOpt, validate, onChange);
        const this$ = new FSharpRef(defaultOf_1());
        this$.contents = this;
        this["init@282-1"] = 1;
        GetterSetterBaseField$2__get_Field(this$.contents).IsRequired = false;
    }
}

export function TextAreaFieldOpt$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TextAreaFieldOpt`1", [gen0], TextAreaFieldOpt$1, TextFieldBase$2_$reflection(gen0, option_type(string_type)));
}

export function TextAreaFieldOpt$1_$ctor_773DF4C9(name, fieldName, validate, onChange) {
    return new TextAreaFieldOpt$1(name, fieldName, validate, onChange);
}

function inputWithButtons(isDisabled, incrementFunc, isValueEmpty, callOnChange, input, object, value) {
    const controlColumnDetails = singleton_1(new Option_5(0, [new Screen(0, []), new ISize(5, [])]));
    const getButton = (increment, icon) => button(ofArray([new Option_6(16, [isDisabled ? true : isValueEmpty(value)]), new Option_6(5, []), new Option_6(1, [new Size_ISize(0, [])]), new Option_6(18, [(event) => {
        event.preventDefault();
        const newValue = incrementFunc(increment, value);
        if (!isValueEmpty(newValue)) {
            callOnChange(object, newValue);
        }
    }])]), singleton_1(icon_1(singleton_1(new Option_7(0, [new Size_ISize(0, [])])), singleton_1(Fa_i(singleton_1(icon), [])))));
    return columns(singleton_1(new Option_8(4, [])), ofArray([column(controlColumnDetails, singleton_1(getButton(-1, new Fa_IconOption(11, ["fas fa-minus"])))), column(empty(), singleton_1(input)), column(controlColumnDetails, singleton_1(getButton(1, new Fa_IconOption(11, ["fas fa-plus"]))))]));
}

export class NumericFieldBase$2 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, fromValue, toValue, validate, onChange, emptyValues, incrementFunc) {
        super(name, fieldName, validate, onChange);
        this.fromValue = fromValue;
        this.toValue = toValue;
        this.emptyValues = emptyValues;
        this.incrementFunc = incrementFunc;
        this.withButtons = (this.incrementFunc != null);
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, value) {
        const this$ = this;
        const strValue = NumericFieldBase$2__IsValueEmpty_2B594(this$, value) ? "" : this$.fromValue(value);
        const input = input_1(ofArray([new Option_4(1, [new IInputType(7, [])]), new Option_4(2, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_4(12, [GetterSetterBaseField$2__get_Placeholder(this$)]), new Option_4(8, [strValue]), new Option_4(13, [(event) => {
            NumericFieldBase$2__ProcessOnChange(this$, object, value, event);
        }]), new Option_4(4, [isDisabled])]));
        return this$.withButtons ? inputWithButtons(isDisabled, uncurry2(value_18(map(curry2, this$.incrementFunc))), (value_1) => NumericFieldBase$2__IsValueEmpty_2B594(this$, value_1), (object_2, newValue) => {
            GetterSetterBaseField$2__CallOnChange(this$, object_2, newValue);
        }, input, object, value) : input;
    }
}

export function NumericFieldBase$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.NumericFieldBase`2", [gen0, gen1], NumericFieldBase$2, JsGetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function NumericFieldBase$2_$ctor_73961A4A(name, fieldName, fromValue, toValue, validate, onChange, emptyValues, incrementFunc) {
    return new NumericFieldBase$2(name, fieldName, fromValue, toValue, validate, onChange, emptyValues, incrementFunc);
}

function NumericFieldBase$2__ProcessOnChangeEx(this$, object, currentValue, event, valueModifier) {
    const elem = event.target;
    if (isNullOrWhiteSpace(elem.value) && (FSharpSet__get_Count(this$.emptyValues) === 1)) {
        GetterSetterBaseField$2__CallOnChange(this$, object, head(this$.emptyValues));
    }
    else {
        const matchValue = this$.toValue(elem.value);
        if (matchValue.tag === 1) {
        }
        else {
            const finalValue = valueModifier(matchValue.fields[0]);
            if (!equals(finalValue, currentValue)) {
                GetterSetterBaseField$2__CallOnChange(this$, object, finalValue);
            }
        }
    }
}

function NumericFieldBase$2__ProcessOnChange(this$, object, currentValue, event) {
    event.preventDefault();
    NumericFieldBase$2__ProcessOnChangeEx(this$, object, currentValue, event, (x) => x);
}

function NumericFieldBase$2__IsValueEmpty_2B594(this$, value) {
    return contains(value, this$.emptyValues);
}

const tryParseInt = (arg_1) => {
    let _arg;
    let outArg = 0;
    _arg = [tryParse(arg_1, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    return _arg[0] ? (new FSharpResult$2(0, [_arg[1]])) : (new FSharpResult$2(1, ["Can\'t parse int"]));
};

const tryParseIntOpt = (arg_1) => {
    let _arg;
    let outArg = 0;
    _arg = [tryParse(arg_1, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    return _arg[0] ? (new FSharpResult$2(0, [_arg[1]])) : (new FSharpResult$2(1, ["Can\'t parse int"]));
};

const tryParseDouble = (arg_1) => {
    let _arg;
    let outArg = 0;
    _arg = [tryParse_1(arg_1, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    return _arg[0] ? (new FSharpResult$2(0, [_arg[1]])) : (new FSharpResult$2(1, ["Can\'t parse double"]));
};

export class IntField$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, int32ToString, tryParseInt, validate, onChange, empty_2({
            Compare: comparePrimitives,
        }), void 0);
    }
}

export function IntField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.IntField`1", [gen0], IntField$1, NumericFieldBase$2_$reflection(gen0, int32_type));
}

export function IntField$1_$ctor_1CFD88AD(name, fieldName, validate, onChange) {
    return new IntField$1(name, fieldName, validate, onChange);
}

export class PositiveIntField$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, int32ToString, tryParseInt, validate, onChange, ofSeq([0], {
            Compare: comparePrimitives,
        }), (step, value_1) => (value_1 + step));
    }
}

export function PositiveIntField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.PositiveIntField`1", [gen0], PositiveIntField$1, NumericFieldBase$2_$reflection(gen0, int32_type));
}

export function PositiveIntField$1_$ctor_1CFD88AD(name, fieldName, validate, onChange) {
    return new PositiveIntField$1(name, fieldName, validate, onChange);
}

export class PositiveIntOptField$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, toString, tryParseIntOpt, validate, onChange, ofSeq([0], {
            Compare: compare,
        }), (step, option) => map((x_1) => (x_1 + step), option));
    }
}

export function PositiveIntOptField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.PositiveIntOptField`1", [gen0], PositiveIntOptField$1, NumericFieldBase$2_$reflection(gen0, option_type(int32_type)));
}

export function PositiveIntOptField$1_$ctor_Z7DEF6452(name, fieldName, validate, onChange) {
    return new PositiveIntOptField$1(name, fieldName, validate, onChange);
}

function optToString(value) {
    if (value == null) {
        return "";
    }
    else {
        let copyOfStruct = value_18(value);
        return toString(copyOfStruct);
    }
}

function stringToOptInt(s) {
    if (isNullOrWhiteSpace(s)) {
        return new FSharpResult$2(0, [void 0]);
    }
    else {
        return Result_Map((Value) => Value, tryParseInt(s));
    }
}

export class IntFieldOpt$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, optToString, stringToOptInt, validate, onChange, empty_2({
            Compare: compare,
        }), void 0);
        const this$ = new FSharpRef(defaultOf_1());
        this$.contents = this;
        this["init@443-2"] = 1;
        GetterSetterBaseField$2__get_Field(this$.contents).IsRequired = false;
    }
}

export function IntFieldOpt$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.IntFieldOpt`1", [gen0], IntFieldOpt$1, NumericFieldBase$2_$reflection(gen0, option_type(int32_type)));
}

export function IntFieldOpt$1_$ctor_Z7DEF6452(name, fieldName, validate, onChange) {
    return new IntFieldOpt$1(name, fieldName, validate, onChange);
}

export class FloatField$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, (value) => value.toString(), tryParseDouble, validate, onChange, empty_2({
            Compare: comparePrimitives,
        }), void 0);
    }
}

export function FloatField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FloatField`1", [gen0], FloatField$1, NumericFieldBase$2_$reflection(gen0, float64_type));
}

export function FloatField$1_$ctor_Z618923D6(name, fieldName, validate, onChange) {
    return new FloatField$1(name, fieldName, validate, onChange);
}

export class PositiveFloatField$1 extends NumericFieldBase$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, (value) => value.toString(), tryParseDouble, validate, onChange, ofSeq([0], {
            Compare: comparePrimitives,
        }), (step, value_1) => (value_1 + (step * 1)));
    }
}

export function PositiveFloatField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.PositiveFloatField`1", [gen0], PositiveFloatField$1, NumericFieldBase$2_$reflection(gen0, float64_type));
}

export function PositiveFloatField$1_$ctor_Z618923D6(name, fieldName, validate, onChange) {
    return new PositiveFloatField$1(name, fieldName, validate, onChange);
}

export class BaseDateTimeField$2 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, convertToDate, convertFromDate, isSet, validate, onChange, formatString, inputFunc) {
        super(name, fieldName, validate, onChange);
        this.convertToDate = convertToDate;
        this.convertFromDate = convertFromDate;
        this.isSet = isSet;
        this.formatString = formatString;
        this.inputFunc = inputFunc;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, date) {
        const this$ = this;
        const value = this$.isSet(date) ? singleton_1(new Option_4(8, [toString_1(toLocalTime(this$.convertToDate(date)), this$.formatString)])) : empty();
        return this$.inputFunc(append_1(ofArray([new Option_4(2, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_4(12, [GetterSetterBaseField$2__get_Placeholder(this$)]), new Option_4(13, [(event) => {
            BaseDateTimeField$2__ProcessOnChange(this$, object, event);
        }]), new Option_4(4, [isDisabled])]), value));
    }
}

export function BaseDateTimeField$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.BaseDateTimeField`2", [gen0, gen1], BaseDateTimeField$2, JsGetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function BaseDateTimeField$2_$ctor_Z3AB08EEA(name, fieldName, convertToDate, convertFromDate, isSet, validate, onChange, formatString, inputFunc) {
    return new BaseDateTimeField$2(name, fieldName, convertToDate, convertFromDate, isSet, validate, onChange, formatString, inputFunc);
}

function BaseDateTimeField$2__ProcessOnChange(this$, object, event) {
    const elem = event.target;
    let parsed = minValue();
    if (!isNullOrWhiteSpace(elem.value) && tryParse_2(elem.value, new FSharpRef(() => parsed, (v) => {
        parsed = v;
    }))) {
        GetterSetterBaseField$2__CallOnChange(this$, object, this$.convertFromDate(toUniversalTime(parsed)));
    }
    else {
        console.log(some(`DATE change ignored: ${elem.value}; ${elem.valueAsDate}`));
    }
}

const dateTimeFormatString = "yyyy-MM-ddTHH:mm";

export class DateTimeField$1 extends BaseDateTimeField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, (x) => x, (x_1) => x_1, (_arg) => true, validate, onChange, dateTimeFormatString, (options) => input_1(cons(new Option_4(1, [new IInputType(2, [])]), options)));
    }
}

export function DateTimeField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DateTimeField`1", [gen0], DateTimeField$1, BaseDateTimeField$2_$reflection(gen0, class_type("System.DateTime")));
}

export function DateTimeField$1_$ctor_4797505E(name, fieldName, validate, onChange) {
    return new DateTimeField$1(name, fieldName, validate, onChange);
}

export class DateTimeOptField$1 extends BaseDateTimeField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, value_18, (Value) => Value, (option_1) => (option_1 != null), validate, onChange, dateTimeFormatString, (options) => input_1(cons(new Option_4(1, [new IInputType(2, [])]), options)));
    }
}

export function DateTimeOptField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DateTimeOptField`1", [gen0], DateTimeOptField$1, BaseDateTimeField$2_$reflection(gen0, option_type(class_type("System.DateTime"))));
}

export function DateTimeOptField$1_$ctor_86BCFBD(name, fieldName, validate, onChange) {
    return new DateTimeOptField$1(name, fieldName, validate, onChange);
}

export class DateField$1 extends BaseDateTimeField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, (x) => x, (x_1) => x_1, (_arg) => true, validate, onChange, "yyyy-MM-dd", (options) => input_1(cons(new Option_4(1, [new IInputType(3, [])]), options)));
    }
}

export function DateField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DateField`1", [gen0], DateField$1, BaseDateTimeField$2_$reflection(gen0, class_type("System.DateTime")));
}

export function DateField$1_$ctor_4797505E(name, fieldName, validate, onChange) {
    return new DateField$1(name, fieldName, validate, onChange);
}

export class DurationSecsFieldBase$1 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, validate, onChange, withSeconds) {
        super(name, fieldName, validate, onChange);
        this.withSeconds = withSeconds;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, durationSecs) {
        const this$ = this;
        const patternInput = splitSeconds(durationSecs);
        const now = toLocalTime(now_1());
        const dateStr = toString_1(toLocalTime(create(year(now), month(now), day(now), patternInput[0], patternInput[1], patternInput[2], 0, 2)), "HH:mm:ss");
        return inputWithButtons(isDisabled, (increment, oldValue) => DurationSecsFieldBase$1__incrementFunc(this$, increment, oldValue), (value) => DurationSecsFieldBase$1__isValueEmpty_Z524259A4(this$, value), (object_2, newValue) => {
            GetterSetterBaseField$2__CallOnChange(this$, object_2, newValue);
        }, input_1(cons(new Option_4(1, [new IInputType(5, [])]), toList(delay(() => append(singleton(new Option_4(2, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))])), delay(() => append(singleton(new Option_4(12, [GetterSetterBaseField$2__get_Placeholder(this$)])), delay(() => append(singleton(new Option_4(8, [dateStr])), delay(() => append(singleton(new Option_4(13, [(event) => {
            DurationSecsFieldBase$1__ProcessOnChange(this$, object, event);
        }])), delay(() => append(singleton(new Option_4(4, [isDisabled])), delay(() => (this$.withSeconds ? singleton(new Option_4(15, [singleton_1(new HTMLAttr(154, [1]))])) : empty_1()))))))))))))))), object, durationSecs);
    }
}

export function DurationSecsFieldBase$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DurationSecsFieldBase`1", [gen0], DurationSecsFieldBase$1, JsGetterSetterBaseField$2_$reflection(gen0, int32_type));
}

export function DurationSecsFieldBase$1_$ctor_5CEDACA7(name, fieldName, validate, onChange, withSeconds) {
    return new DurationSecsFieldBase$1(name, fieldName, validate, onChange, withSeconds);
}

function DurationSecsFieldBase$1__ProcessOnChange(this$, object, event) {
    const elem = event.target;
    if (!isNullOrWhiteSpace(elem.value) && !(elem.valueAsDate == null)) {
        const newDate = parse(elem.value);
        GetterSetterBaseField$2__CallOnChange(this$, object, (((hour(newDate) * 60) + minute(newDate)) * 60) + second(newDate));
    }
}

export function DurationSecsFieldBase$1__incrementFunc(this$, increment, oldValue) {
    return oldValue + ((this$.withSeconds ? 15 : 60) * increment);
}

export function DurationSecsFieldBase$1__isValueEmpty_Z524259A4(this$, value) {
    return value < 0;
}

export class DurationSecsField$1 extends DurationSecsFieldBase$1 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, validate, onChange, false);
    }
}

export function DurationSecsField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DurationSecsField`1", [gen0], DurationSecsField$1, DurationSecsFieldBase$1_$reflection(gen0));
}

export function DurationSecsField$1_$ctor_1CFD88AD(name, fieldName, validate, onChange) {
    return new DurationSecsField$1(name, fieldName, validate, onChange);
}

export class DurationSecsFieldWithSeconds$1 extends DurationSecsFieldBase$1 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, validate, onChange, true);
    }
}

export function DurationSecsFieldWithSeconds$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.DurationSecsFieldWithSeconds`1", [gen0], DurationSecsFieldWithSeconds$1, DurationSecsFieldBase$1_$reflection(gen0));
}

export function DurationSecsFieldWithSeconds$1_$ctor_1CFD88AD(name, fieldName, validate, onChange) {
    return new DurationSecsFieldWithSeconds$1(name, fieldName, validate, onChange);
}

export class LocationField$1 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, suggestions, onSuggestionChosen, validate, onChange) {
        super(name, fieldName, validate, onChange);
        this.suggestions = suggestions;
        this.onSuggestionChosen = onSuggestionChosen;
        this.maxNameLength = 20;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, location) {
        const this$ = this;
        const currentLocation = defaultArg(location, "");
        const children = toList(delay(() => append(singleton(input_1(ofArray([new Option_4(1, [new IInputType(0, [])]), new Option_4(3, [Config_placeLookupId]), new Option_4(2, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_4(12, [GetterSetterBaseField$2__get_Placeholder(this$)]), new Option_4(8, [currentLocation]), new Option_4(13, [(event) => {
            LocationField$1__ProcessOnChange(this$, object, event);
        }]), new Option_4(4, [isDisabled])]))), delay(() => (!isEmpty(this$.suggestions) ? singleton(list_12(empty(), map_1((suggestion) => LocationField$1__RenderSuggestion(this$, isDisabled, currentLocation, suggestion), this$.suggestions))) : empty_1())))));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    "Omnicv.Client.Common.Components.FlexForm.BaseField`1.Validate2B595"(object) {
        const this$ = this;
        return FieldValidationResult__Merge_30561432((GetterSetterBaseField$2__GetValue_2B595(this$, object) == null) ? (new FieldValidationResult(1, ["Location value is not set"])) : (new FieldValidationResult(0, [])), super["Omnicv.Client.Common.Components.FlexForm.BaseField`1.Validate2B595"](object));
    }
}

export function LocationField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.LocationField`1", [gen0], LocationField$1, JsGetterSetterBaseField$2_$reflection(gen0, option_type(string_type)));
}

export function LocationField$1_$ctor_455E8BE1(name, fieldName, suggestions, onSuggestionChosen, validate, onChange) {
    return new LocationField$1(name, fieldName, suggestions, onSuggestionChosen, validate, onChange);
}

function LocationField$1__ProcessOnChange(this$, object, event) {
    let value;
    const elem = event.target;
    GetterSetterBaseField$2__CallOnChange(this$, object, (value = elem.value, isNullOrWhiteSpace(value) ? void 0 : value));
}

function LocationField$1__RenderSuggestion(this$, isDisabled, currentLocation, suggestion) {
    const name = suggestion.Name;
    if (currentLocation !== name) {
        let distance;
        if (suggestion.DistanceFromCurrent != null) {
            const dist = ~~value_18(suggestion.DistanceFromCurrent) | 0;
            distance = ((dist > 1000) ? ": >1km" : (`: ${dist}m`));
        }
        else {
            distance = "";
        }
        return button(ofArray([new Option_6(0, [isDisabled ? (new Color_IColor(14, [])) : (new Color_IColor(4, []))]), new Option_6(1, [new Size_ISize(0, [])]), new Option_6(16, [isDisabled]), new Option_6(18, [(event) => {
            event.preventDefault();
            this$.onSuggestionChosen(suggestion);
        }])]), singleton_1(`${(name.length > this$.maxNameLength) ? (substring(name, 0, 20) + "…") : name}${distance}`));
    }
    else {
        return defaultOf();
    }
}

function validateChoice(choices, nextValidate, value) {
    if (!exists((pair) => equals(value, pair[0]), choices)) {
        return `Value ${value} is not a valid choice`;
    }
    else {
        return nextValidate(value);
    }
}

export class SelectField$2 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, choices, convert_1, validate, onChange) {
        super(name, fieldName, (value) => validateChoice(choices, validate, value), onChange);
        this.name_2 = name;
        this.choices = choices;
        this.convert = convert_1;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, value) {
        let props, copyOfStruct;
        const this$ = this;
        const options = valuesToSelectOptions(this$.choices, this$.name_2);
        return select(ofArray([new Option_9(7, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(4, []))]), new Option_9(6, [isDisabled])]), singleton_1((props = [new DOMAttr(9, [(event) => {
            SelectField$2__ProcessOnChange(this$, object, event);
        }]), new HTMLAttr(161, [(copyOfStruct = value, toString(copyOfStruct))])], react.createElement("select", keyValueList(props, 1), ...options))));
    }
}

export function SelectField$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.SelectField`2", [gen0, gen1], SelectField$2, JsGetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function SelectField$2_$ctor_Z7732D262(name, fieldName, choices, convert_1, validate, onChange) {
    return new SelectField$2(name, fieldName, choices, convert_1, validate, onChange);
}

function SelectField$2__ProcessOnChange(this$, object, event) {
    const elem = event.target;
    GetterSetterBaseField$2__CallOnChange(this$, object, this$.convert(elem.value));
}

export class RadioField$2 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, choices, validate, onChange) {
        super(name, fieldName, (value) => validateChoice(choices, validate, value), onChange);
        this.choices = choices;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, error, object, value) {
        const this$ = this;
        const y = map_1((tupledArg) => RadioField$2__RenderChoice(this$, isDisabled, object, value, tupledArg[0], tupledArg[1]), this$.choices);
        return div(singleton_1(new Option_10(12, [singleton_1(new Modifier_IModifier(1, [FieldValidationResult__ToColour_Z5463E381(error, new Color_IColor(0, []))]))])), y);
    }
}

export function RadioField$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.RadioField`2", [gen0, gen1], RadioField$2, JsGetterSetterBaseField$2_$reflection(gen0, gen1));
}

export function RadioField$2_$ctor_14949C87(name, fieldName, choices, validate, onChange) {
    return new RadioField$2(name, fieldName, choices, validate, onChange);
}

function RadioField$2__ProcessOnChange(this$, object, newValue, _event) {
    GetterSetterBaseField$2__CallOnChange(this$, object, newValue);
}

function RadioField$2__RenderChoice(this$, isDisabled, object, currentValue, optionValue, caption) {
    let copyOfStruct;
    return radioInline(ofArray([new Option_11(6, [equals(currentValue, optionValue)]), new Option_11(14, [(copyOfStruct = optionValue, toString(copyOfStruct))]), new Option_11(13, [`radio-${optionValue}`]), new Option_11(11, [(_event) => {
        RadioField$2__ProcessOnChange(this$, object, optionValue, _event);
    }]), new Option_11(7, [isDisabled])]), singleton_1(` ${caption} `));
}

export class CheckboxField$1 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, validate, onChange);
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, _error, object, value) {
        const this$ = this;
        return checkbox(empty(), ofArray([input_2(singleton_1(new Common_GenericOption(1, [ofArray([new HTMLAttr(62, [value]), new DOMAttr(9, [(event) => {
            CheckboxField$1__ProcessOnChange(this$, object, event);
        }]), new HTMLAttr(79, [isDisabled])])]))), ""]));
    }
}

export function CheckboxField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.CheckboxField`1", [gen0], CheckboxField$1, JsGetterSetterBaseField$2_$reflection(gen0, bool_type));
}

export function CheckboxField$1_$ctor_Z387AD5E5(name, fieldName, validate, onChange) {
    return new CheckboxField$1(name, fieldName, validate, onChange);
}

function CheckboxField$1__ProcessOnChange(this$, object, event) {
    const elem = event.target;
    GetterSetterBaseField$2__CallOnChange(this$, object, elem.checked);
}

export class TagsField$1 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, tagsPredictionValues, validate, onChange) {
        super(name, fieldName, validate, onChange);
        this.tagsPredictionValues = tagsPredictionValues;
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, _error, object, rawValue) {
        const this$ = this;
        let safeProps;
        const arg_10 = ["maxTags", 2147483647];
        const name_10 = arg_10[0];
        let props_12;
        const arg_9 = ["autoTrim", true];
        const name_9 = arg_9[0];
        let props_11;
        const arg_8 = ["tagProperties", singleton_1(["className", "is-rounded"])];
        const name_8 = arg_8[0];
        let props_10;
        const arg_7 = ["tagsRemovable", true];
        const name_7 = arg_7[0];
        let props_9;
        const arg_6 = ["closeDropdownOnItemSelect", true];
        const name_6 = arg_6[0];
        let props_8;
        const arg_5 = ["allowDuplicates", true];
        const name_5 = arg_5[0];
        let props_7;
        const arg_4 = ["caseSensitive", true];
        const name_4 = arg_4[0];
        let props_6;
        const arg_3 = ["placeholder", "Choose tags"];
        const name_3 = arg_3[0];
        let props_5;
        const arg_2 = ["delimiter", ","];
        const name_2 = arg_2[0];
        let props_4;
        const arg_1 = ["loadingLabel", "Loading..."];
        const name_1 = arg_1[0];
        let props_3;
        const arg = ["noResultsLabel", "No results found"];
        const name = arg[0];
        const props_2 = ofArray([["autoTrim", true], ["allowDuplicates", false], ["allowOnlyAutoCompleteValues", false], ["removeSelectedFromAutoComplete", true], ["value", toClientListView(rawValue)], ["placeholder", GetterSetterBaseField$2__get_Placeholder(this$)], ["autoCompleteSource", (text) => singleton_2.Delay(() => {
            if (isNullOrWhiteSpace(text)) {
                return singleton_2.Return(empty());
            }
            else {
                const targetText = text.toLowerCase();
                const matches = filter((tag) => (tag.toLowerCase().indexOf(targetText) >= 0), this$.tagsPredictionValues);
                return singleton_2.Return(matches);
            }
        })], ["onTagsChanged", (newTags) => {
            GetterSetterBaseField$2__CallOnChange(this$, object, concatToServer(newTags));
        }]]);
        props_3 = (exists((tupledArg) => (tupledArg[0] === name), map_1((value_1_1) => value_1_1, props_2)) ? props_2 : cons([name, arg[1]], props_2));
        props_4 = (exists((tupledArg_1) => (tupledArg_1[0] === name_1), map_1((value_1_2) => value_1_2, props_3)) ? props_3 : cons([name_1, arg_1[1]], props_3));
        props_5 = (exists((tupledArg_2) => (tupledArg_2[0] === name_2), map_1((value_1_3) => value_1_3, props_4)) ? props_4 : cons([name_2, arg_2[1]], props_4));
        props_6 = (exists((tupledArg_3) => (tupledArg_3[0] === name_3), map_1((value_1_4) => value_1_4, props_5)) ? props_5 : cons([name_3, arg_3[1]], props_5));
        props_7 = (exists((tupledArg_4) => (tupledArg_4[0] === name_4), map_1((value_1_5) => value_1_5, props_6)) ? props_6 : cons([name_4, arg_4[1]], props_6));
        props_8 = (exists((tupledArg_5) => (tupledArg_5[0] === name_5), map_1((value_1_6) => value_1_6, props_7)) ? props_7 : cons([name_5, arg_5[1]], props_7));
        props_9 = (exists((tupledArg_6) => (tupledArg_6[0] === name_6), map_1((value_1_7) => value_1_7, props_8)) ? props_8 : cons([name_6, arg_6[1]], props_8));
        props_10 = (exists((tupledArg_7) => (tupledArg_7[0] === name_7), map_1((value_1_8) => value_1_8, props_9)) ? props_9 : cons([name_7, arg_7[1]], props_9));
        props_11 = (exists((tupledArg_8) => (tupledArg_8[0] === name_8), map_1((value_1_9) => value_1_9, props_10)) ? props_10 : cons([name_8, arg_8[1]], props_10));
        props_12 = (exists((tupledArg_9) => (tupledArg_9[0] === name_9), map_1((value_1_10) => value_1_10, props_11)) ? props_11 : cons([name_9, arg_9[1]], props_11));
        safeProps = (exists((tupledArg_10) => (tupledArg_10[0] === name_10), map_1((value_1_11) => value_1_11, props_12)) ? props_12 : cons([name_10, arg_10[1]], props_12));
        return createElement(TagsInputComponent_input, {
            p: createObj(safeProps),
        });
    }
}

export function TagsField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.TagsField`1", [gen0], TagsField$1, JsGetterSetterBaseField$2_$reflection(gen0, string_type));
}

export function TagsField$1_$ctor_32D33D73(name, fieldName, tagsPredictionValues, validate, onChange) {
    return new TagsField$1(name, fieldName, tagsPredictionValues, validate, onChange);
}

export class RatingField$1 extends JsGetterSetterBaseField$2 {
    constructor(name, fieldName, validate, onChange) {
        super(name, fieldName, validate, onChange);
    }
    "Omnicv.Client.Common.Components.FlexForm.GetterSetterBaseField`2.RenderValue"(isDisabled, _error, object, rawValue) {
        const this$ = this;
        const converted = rawValue / 2;
        return ratingStars(append_1(ofArray([new RatingStarsProps(1, [5]), new RatingStarsProps(4, [24]), new RatingStarsProps(3, [true]), new RatingStarsProps(2, [!isDisabled]), new RatingStarsProps(6, [(newValue) => {
            GetterSetterBaseField$2__CallOnChange(this$, object, (newValue * 2) & 0xFF);
        }])]), (converted > 0) ? singleton_1(new RatingStarsProps(5, [converted])) : empty()));
    }
}

export function RatingField$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.RatingField`1", [gen0], RatingField$1, JsGetterSetterBaseField$2_$reflection(gen0, uint8_type));
}

export function RatingField$1_$ctor_B10D3B5(name, fieldName, validate, onChange) {
    return new RatingField$1(name, fieldName, validate, onChange);
}

export class FlexFormFields$1 {
    constructor(onChange, callbackOnFieldAdd) {
        this.onChange = onChange;
        this.callbackOnFieldAdd = callbackOnFieldAdd;
        this.fields = [];
    }
}

export function FlexFormFields$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexFormFields`1", [gen0], FlexFormFields$1);
}

export function FlexFormFields$1_$ctor_Z63A200CD(onChange, callbackOnFieldAdd) {
    return new FlexFormFields$1(onChange, callbackOnFieldAdd);
}

export class FlexFormFieldsGroup$1 extends FlexFormFields$1 {
    constructor(onChange, label) {
        super(onChange);
        this.label = label;
    }
    Render(isDisabled, object) {
        const this$ = this;
        return div(singleton_1(new Option_10(8, [])), toList(delay(() => append((this$.label != null) ? singleton(label_2(empty(), singleton_1(value_18(this$.label)))) : empty_1(), delay(() => singleton(body(empty(), FlexFormFields$1__Render(this$, isDisabled, object))))))));
    }
    Validate(object) {
        const this$ = this;
        return FlexFormFields$1__ValidateAll_2B595(this$, object);
    }
}

export function FlexFormFieldsGroup$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexFormFieldsGroup`1", [gen0], FlexFormFieldsGroup$1, FlexFormFields$1_$reflection(gen0));
}

export function FlexFormFieldsGroup$1_$ctor_38CD3B67(onChange, label) {
    return new FlexFormFieldsGroup$1(onChange, label);
}

function FlexFormFields$1__AddField_6235DF45(this$, field) {
    if (this$.callbackOnFieldAdd != null) {
        value_18(this$.callbackOnFieldAdd)(field);
    }
    void (this$.fields.push(field));
}

export function FlexFormFields$1__ValidateAll_2B595(this$, value) {
    return mergeResults(map_2((field) => field.Validate(value), this$.fields));
}

export function FlexFormFields$1__CanSubmit_2B595(this$, value) {
    return mergeResults(map_2((field) => field.Validate(value), this$.fields));
}

function FlexFormFields$1__BuildAddField(this$, fieldBuilder, fieldName, validate, caption0, onChange0, help, required) {
    const field = fieldBuilder(defaultArg(caption0, fieldName), fieldName, validate, defaultArg(onChange0, this$.onChange));
    if (help != null) {
        field.Help = help;
    }
    if (required != null) {
        field.IsRequired = value_18(required);
    }
    FlexFormFields$1__AddField_6235DF45(this$, field);
    return this$;
}

export function FlexFormFields$1__AddCustom_693A43B3(this$, fieldName, customRender) {
    FlexFormFields$1__AddField_6235DF45(this$, CustomField$1_$ctor_693A43B3(fieldName, customRender));
    return this$;
}

export function FlexFormFields$1__AddText_Z44011C05(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, TextField$1_$ctor_7EE3C92A, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddTextOpt_Z44636648(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, TextFieldOpt$1_$ctor_773DF4C9, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddTextArea_Z44011C05(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, TextAreaField$1_$ctor_7EE3C92A, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddTextAreaOpt_Z44636648(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, TextAreaFieldOpt$1_$ctor_773DF4C9, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddInt_ZAAC24(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, IntField$1_$ctor_1CFD88AD, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddPositiveInt_ZAAC24(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, PositiveIntField$1_$ctor_1CFD88AD, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddPositiveIntOpt_414042FF(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, PositiveIntOptField$1_$ctor_Z7DEF6452, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddIntOpt_414042FF(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, IntFieldOpt$1_$ctor_Z7DEF6452, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddFloat_1D6F52FB(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, FloatField$1_$ctor_Z618923D6, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddPositiveFloat_1D6F52FB(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, PositiveFloatField$1_$ctor_Z618923D6, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddDateTime_2636DDCF(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, DateTimeField$1_$ctor_4797505E, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddDateTimeOpt_C9F34CC(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, DateTimeOptField$1_$ctor_86BCFBD, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddDate_2636DDCF(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, DateField$1_$ctor_4797505E, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddRating_Z716E223C(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, RatingField$1_$ctor_B10D3B5, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddDurationSecs_ZAAC24(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, DurationSecsField$1_$ctor_1CFD88AD, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddDurationSecsWithSeconds_ZAAC24(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, DurationSecsFieldWithSeconds$1_$ctor_1CFD88AD, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddTags_Z63D88B1E(this$, fieldName, validate, tagsPredictionValues, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, (caption, fieldName_1, validate_1, onChange) => TagsField$1_$ctor_32D33D73(caption, fieldName_1, tagsPredictionValues, validate_1, onChange), fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddLocation_Z52938230(this$, fieldName, validate, suggestions, onSuggestionChosen, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, (caption, fieldName_1, validate_1, onChange) => LocationField$1_$ctor_455E8BE1(caption, fieldName_1, suggestions, onSuggestionChosen, validate_1, onChange), fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddSelection_158231B6(this$, fieldName, choices, convert_1, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, (caption, fieldName_1, validate_1, onChange) => SelectField$2_$ctor_Z7732D262(caption, fieldName_1, choices, convert_1, validate_1, onChange), fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddSelection_Z70244BEA(this$, fieldName, choices, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, (caption, fieldName_1, validate_1, onChange) => SelectField$2_$ctor_Z7732D262(caption, fieldName_1, choices, (value) => stringToEnum(choices, value), validate_1, onChange), fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddRadiosInline_Z70244BEA(this$, fieldName, choices, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, (caption, fieldName_1, validate_1, onChange) => RadioField$2_$ctor_14949C87(caption, fieldName_1, choices, validate_1, onChange), fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddCheckbox_264AA22A(this$, fieldName, validate, caption0, onChange0, help, required) {
    return FlexFormFields$1__BuildAddField(this$, CheckboxField$1_$ctor_Z387AD5E5, fieldName, validate, caption0, onChange0, help, required);
}

export function FlexFormFields$1__AddGroup_6DFDD678(this$, label) {
    const group = FlexFormFieldsGroup$1_$ctor_38CD3B67(this$.onChange, label);
    FlexFormFields$1__AddField_6235DF45(this$, group);
    return group;
}

export function FlexFormFields$1__Render(this$, isDisabled, value) {
    return ofSeq_1(map_2((field) => field.Render(isDisabled, value), this$.fields));
}

export class FlexFormState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Idle", "Loading", "Error", "Success"];
    }
}

export function FlexFormState_$reflection() {
    return union_type("Omnicv.Client.Common.Components.FlexForm.FlexFormState", [], FlexFormState, () => [[], [], [["Item", string_type]], [["Item", string_type]]]);
}

export function FlexFormState__CanSubmit(this$) {
    switch (this$.tag) {
        case 0:
        case 2:
            return true;
        default:
            return false;
    }
}

export function FlexFormState__IsInProgress(this$) {
    if (this$.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

export class FlexFormBase$1 {
    constructor(submit, cancel) {
        this.submit = submit;
        this.cancel = cancel;
        this["State@"] = (new FlexFormState(0, []));
        this["SubmitCaption@"] = "Submit";
        this["CancelCaption@"] = "Cancel";
    }
}

export function FlexFormBase$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1", [gen0], FlexFormBase$1);
}

export function FlexFormBase$1_$ctor_Z285F6596(submit, cancel) {
    return new FlexFormBase$1(submit, cancel);
}

export function FlexFormBase$1__get_State(__) {
    return __["State@"];
}

export function FlexFormBase$1__set_State_Z3615493C(__, v) {
    __["State@"] = v;
}

export function FlexFormBase$1__get_SubmitCaption(__) {
    return __["SubmitCaption@"];
}

export function FlexFormBase$1__set_SubmitCaption_Z721C83C5(__, v) {
    __["SubmitCaption@"] = v;
}

export function FlexFormBase$1__get_CancelCaption(__) {
    return __["CancelCaption@"];
}

export function FlexFormBase$1__set_CancelCaption_Z721C83C5(__, v) {
    __["CancelCaption@"] = v;
}

export function FlexFormBase$1__TriggerSubmit_2B595(this$, value) {
    const matchValue = this$["Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.CanSubmit2B595"](value);
    switch (matchValue.tag) {
        case 2: {
            break;
        }
        case 1: {
            if (window.confirm(`Are you sure you want to submit? Warnings:
${matchValue.fields[0]}`)) {
                this$.submit(value);
            }
            break;
        }
        default:
            this$.submit(value);
    }
}

export function FlexFormBase$1__Render_2B595(this$, value) {
    const isInProgress = FlexFormState__IsInProgress(FlexFormBase$1__get_State(this$));
    const buttons = toList(delay(() => {
        const cannotSubmit = FieldValidationResult__IsErrorResult(this$["Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.CanSubmit2B595"](value));
        return singleton(div(singleton_1(new Option_10(4, [])), toList(delay(() => append(singleton(div_1(empty(), singleton_1(button(ofArray([new Option_6(0, [new Color_IColor(4, [])]), new Option_6(16, [cannotSubmit ? true : isInProgress]), new Option_6(18, [(arg_1) => {
            ((event) => {
                event.preventDefault();
                FlexFormBase$1__TriggerSubmit_2B595(this$, value);
            })(arg_1);
        }])]), singleton_1(FlexFormBase$1__get_SubmitCaption(this$)))))), delay(() => ((this$.cancel != null) ? singleton(div_1(empty(), singleton_1(button(ofArray([new Option_6(0, [new Color_IColor(9, [])]), new Option_6(16, [isInProgress]), new Option_6(18, [(arg_2) => {
            arg_2.preventDefault();
            value_18(this$.cancel)();
        }])]), singleton_1(FlexFormBase$1__get_CancelCaption(this$)))))) : empty_1())))))));
    }));
    const children = append_1(this$["Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.RenderFields"](isInProgress, value), buttons);
    return react.createElement("form", {}, ...children);
}

export class FlexForm$1 extends FlexFormBase$1 {
    constructor(onChange, submit, cancel) {
        super(submit, cancel);
        this["Fields@"] = FlexFormFields$1_$ctor_Z63A200CD(onChange);
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.RenderFields"(isDisabled, value) {
        const this$ = this;
        return FlexFormFields$1__Render(FlexForm$1__get_Fields(this$), isDisabled, value);
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.CanSubmit2B595"(value) {
        const this$ = this;
        return !FlexFormState__CanSubmit(FlexFormBase$1__get_State(this$)) ? (new FieldValidationResult(2, [`State ${FlexFormBase$1__get_State(this$)} does not allow submit`])) : FlexFormFields$1__CanSubmit_2B595(FlexForm$1__get_Fields(this$), value);
    }
}

export function FlexForm$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexForm`1", [gen0], FlexForm$1, FlexFormBase$1_$reflection(gen0));
}

export function FlexForm$1_$ctor_2ABDDB55(onChange, submit, cancel) {
    return new FlexForm$1(onChange, submit, cancel);
}

export function FlexForm$1__get_Fields(__) {
    return __["Fields@"];
}

export class FlexFormTuple$2 extends FlexFormBase$1 {
    constructor(onChangeFst, onChangeSnd, submit, cancel) {
        super(submit, cancel);
        let f2, objectArg, f2_1, objectArg_1;
        this.allFields = [];
        this["FieldsFst@"] = FlexFormFields$1_$ctor_Z63A200CD(onChangeFst, (f2 = ((objectArg = this.allFields, (item) => {
            void (objectArg.push(item));
        })), (arg) => {
            f2(new FSharpChoice$2(0, [arg]));
        }));
        this["FieldsSnd@"] = FlexFormFields$1_$ctor_Z63A200CD(onChangeSnd, (f2_1 = ((objectArg_1 = this.allFields, (item_1) => {
            void (objectArg_1.push(item_1));
        })), (arg_1) => {
            f2_1(new FSharpChoice$2(1, [arg_1]));
        }));
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.RenderFields"(isDisabled, value) {
        const this$ = this;
        return ofSeq_1(map_2((field) => {
            if (field.tag === 1) {
                return field.fields[0].Render(isDisabled, value[1]);
            }
            else {
                return field.fields[0].Render(isDisabled, value[0]);
            }
        }, this$.allFields));
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.CanSubmit2B595"(value) {
        const this$ = this;
        return !FlexFormState__CanSubmit(FlexFormBase$1__get_State(this$)) ? (new FieldValidationResult(2, [`State ${FlexFormBase$1__get_State(this$)} does not allow submit`])) : FieldValidationResult__Merge_30561432(FlexFormFields$1__CanSubmit_2B595(FlexFormTuple$2__get_FieldsFst(this$), value[0]), FlexFormFields$1__CanSubmit_2B595(FlexFormTuple$2__get_FieldsSnd(this$), value[1]));
    }
}

export function FlexFormTuple$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexFormTuple`2", [gen0, gen1], FlexFormTuple$2, FlexFormBase$1_$reflection(tuple_type(gen0, gen1)));
}

export function FlexFormTuple$2_$ctor_5AE4FFF0(onChangeFst, onChangeSnd, submit, cancel) {
    return new FlexFormTuple$2(onChangeFst, onChangeSnd, submit, cancel);
}

export function FlexFormTuple$2__get_FieldsFst(__) {
    return __["FieldsFst@"];
}

export function FlexFormTuple$2__get_FieldsSnd(__) {
    return __["FieldsSnd@"];
}

export function noValidation(_arg) {
    return void 0;
}

function renderCurrentEventInfoDetails(_arg, eventInfo) {
    const smallText = (isBold, text) => Text_span(singleton_1(new Common_GenericOption(2, [toList(delay(() => append(singleton(new Modifier_IModifier(3, [new Screen(0, []), new TextSize_Option(6, [])])), delay(() => (isBold ? singleton(new Modifier_IModifier(2, [new TextWeight_Option(3, [])])) : singleton(new Modifier_IModifier(7, [new TextTransform_Option(3, [])])))))))])), singleton_1(text));
    const space = () => Text_span(empty(), singleton_1(" "));
    return container(singleton_1(new Option_12(5, [singleton_1(new Modifier_IModifier(31, [new FlexWrap_Option(1, [])]))])), toList(delay(() => append(singleton(smallText(true, "TZ")), delay(() => append(singleton(space()), delay(() => append(singleton(smallText(false, EventInfo__DisplayTimeZone(eventInfo))), delay(() => append(singleton(space()), delay(() => {
        let clientDate;
        const currentTz = common.getTimezoneName();
        const clientTz = EventInfo__GetClientTz(eventInfo);
        let result;
        if ((clientTz != null) && (currentTz !== value_18(clientTz))) {
            const arg_1 = value_18(clientTz);
            result = common.dateToLuxonTz(eventInfo.EventTimestamp, arg_1);
        }
        else {
            result = common.dateToLuxonTz(eventInfo.EventTimestamp, currentTz);
        }
        clientDate = result.toRFC2822();
        let locationDate;
        const locationTz = EventInfo__GetLocationTz(eventInfo);
        if (locationTz != null) {
            let result_1;
            const arg_5 = value_18(locationTz);
            result_1 = common.dateToLuxonTz(eventInfo.EventTimestamp, arg_5);
            locationDate = result_1.toRFC2822();
        }
        else {
            locationDate = void 0;
        }
        return append((clientDate != null) ? append(singleton(smallText(false, value_18(clientDate))), delay(() => singleton(space()))) : empty_1(), delay(() => append((!equals(locationDate, clientDate) && (locationDate != null)) ? append((clientDate != null) ? append(singleton(smallText(false, "/")), delay(() => singleton(space()))) : empty_1(), delay(() => append(singleton(smallText(false, value_18(locationDate))), delay(() => singleton(space()))))) : empty_1(), delay(() => append(singleton(smallText(true, "Loc")), delay(() => append(singleton(space()), delay(() => {
            const location = StringBuilder_$ctor();
            return append((eventInfo.PlaceName != null) ? ((void StringBuilder__Append_Z721C83C5(location, value_18(eventInfo.PlaceName)), empty_1())) : empty_1(), delay(() => append(((eventInfo.Latitude != null) && (eventInfo.Longitude != null)) ? append((StringBuilder__get_Length(location) > 0) ? ((void StringBuilder__Append_Z721C83C5(location, " | "), empty_1())) : empty_1(), delay(() => {
                StringBuilder__Append_Z721C83C5(StringBuilder__Append_Z721C83C5(location, format("Lat: {0:0.######}", value_18(eventInfo.Latitude))), format(", long: {0:0.######}", value_18(eventInfo.Longitude)));
                return empty_1();
            })) : empty_1(), delay(() => singleton(smallText(false, toString(location)))))));
        }))))))));
    })))))))))));
}

function renderSetStrictBounds(isDisabled, current, onChange) {
    const children_2 = toList(delay(() => append(singleton(createElement("strong", {
        children: Interop_reactApi.Children.toArray(["Strict bounds: "]),
    })), delay(() => collect((b) => singleton(radioInline(ofArray([new Option_11(6, [current === b]), new Option_11(14, [`StrictBoundsLocation${b}`]), new Option_11(13, [`radio-StrictBoundsLocation${b}`]), new Option_11(11, [(_arg) => {
        onChange(!current);
    }]), new Option_11(7, [isDisabled])]), singleton_1(` ${b} `))), [true, false])))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function addEventInfoToFlexForm(form, eventInfo, suggestions) {
    FlexFormFields$1__AddLocation_Z52938230(FlexFormFields$1__AddDateTime_2636DDCF(FlexFormFields$1__AddGroup_6DFDD678(FlexFormTuple$2__get_FieldsFst(form)), "EventTimestamp", noValidation, "Timestamp"), "PlaceName", noValidation, suggestions, (suggestion) => {
        triggerUpdateLocation(suggestion);
    }, "Location");
    FlexFormFields$1__AddCustom_693A43B3(FlexFormTuple$2__get_FieldsFst(form), "Details", renderCurrentEventInfoDetails);
}

export function addEventInfoToFlexFormEim(form, eimModel, onStrictBoundsChange, suggestions) {
    addEventInfoToFlexForm(form, eimModel.EventInfo, suggestions);
    FlexFormFields$1__AddCustom_693A43B3(FlexFormTuple$2__get_FieldsFst(form), "Strict boundaries", (isDisabled, _arg) => renderSetStrictBounds(isDisabled, eimModel.StrictBounds, onStrictBoundsChange));
}

export function getSuggestions(current, data) {
    return sortBy((value_1) => value_1.DistanceFromCurrent, List_distinct(filter((value) => {
        if (value.Name !== "") {
            return value.PlaceId !== "";
        }
        else {
            return false;
        }
    }, map_1((eventInfo) => {
        const position = eventInfoCoordinatesToPositionOrDefault(eventInfo);
        const distance = map((pos) => distanceInMetersBetweenPositions(position, pos), current);
        return new LocationDescriptor(defaultArg(eventInfo.PlaceId, ""), defaultArg(eventInfo.PlaceName, ""), position, distance, eventInfo.LocationTzOffsetMin);
    }, map_1((tuple) => tuple[0], data))), {
        Equals: equals,
        GetHashCode: safeHash,
    }), {
        Compare: compare,
    });
}

export function addEventInfoWithSuggestionsToFlexForm(form, eventInfo, current, data) {
    addEventInfoToFlexForm(form, eventInfo, getSuggestions(current, data));
}

export function addEventInfoWithOptSuggestionsToFlexForm(form, eventInfo, current, data) {
    addEventInfoWithSuggestionsToFlexForm(form, eventInfo, current, defaultArg(data, empty()));
}

export class Formlet$1 extends Record {
    constructor(Render, CanSubmit) {
        super();
        this.Render = Render;
        this.CanSubmit = CanSubmit;
    }
}

export function Formlet$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Common.Components.FlexForm.Formlet`1", [gen0], Formlet$1, () => [["Render", lambda_type(bool_type, lambda_type(gen0, class_type("Fable.React.ReactElement")))], ["CanSubmit", lambda_type(gen0, FieldValidationResult_$reflection())]]);
}

export class FlexFormWithFormlets$1 extends FlexFormBase$1 {
    constructor(formlets, submit, cancel) {
        super(submit, cancel);
        this.formlets = formlets;
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.RenderFields"(isDisabled, value) {
        const this$ = this;
        return map_1((formlet) => formlet.Render(isDisabled, value), this$.formlets);
    }
    "Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.CanSubmit2B595"(value) {
        const this$ = this;
        return !FlexFormState__CanSubmit(FlexFormBase$1__get_State(this$)) ? (new FieldValidationResult(2, [`State ${FlexFormBase$1__get_State(this$)} does not allow submit`])) : mergeResults(map_1((formlet) => formlet.CanSubmit(value), this$.formlets));
    }
}

export function FlexFormWithFormlets$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Common.Components.FlexForm.FlexFormWithFormlets`1", [gen0], FlexFormWithFormlets$1, FlexFormBase$1_$reflection(gen0));
}

export function FlexFormWithFormlets$1_$ctor_Z7DF2018A(formlets, submit, cancel) {
    return new FlexFormWithFormlets$1(formlets, submit, cancel);
}

