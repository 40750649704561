import { toString, Union, Record } from "../fable_modules/fable-library.4.5.0/Types.js";
import { AnonymousService_RouteBuilder, Request_ExternalAuthScheme, Request_Login, Response_UserInfo_$reflection, Request_Login_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, class_type, record_type, option_type } from "../fable_modules/fable-library.4.5.0/Reflection.js";
import { View_ViewConfig$2, View_Validation, View_Action$1, succeed, checkboxField, append, passwordField as passwordField_1, textField, View_Model$1, View_State, View_idle, View_Model$1_$reflection } from "../fable_modules/Fable.Form.Simple.4.0.0/Form.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { RouteModule_toHash, Route, Router_newUrl } from "../Omnicv.Client.Common/Router.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.5.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.5.0/Choice.js";
import { singleton as singleton_1, ofArray, empty } from "../fable_modules/fable-library.4.5.0/List.js";
import { Attributes$1 } from "../fable_modules/Fable.Form.Simple.4.0.0/TextField.fs.js";
import { FieldConfig$4 } from "../fable_modules/Fable.Form.2.0.0/Base.fs.js";
import { Attributes } from "../fable_modules/Fable.Form.Simple.4.0.0/CheckboxField.fs.js";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../fable_modules/fable-library.4.5.0/Seq.js";
import { Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { h2 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { asHtml } from "../fable_modules/Fable.Form.Simple.Bulma.4.0.0/Form.fs.js";

export class LoginData extends Record {
    constructor(Request, Response) {
        super();
        this.Request = Request;
        this.Response = Response;
    }
}

export function LoginData_$reflection() {
    return record_type("Omnicv.Client.Pages.Login.Component.LoginData", [], LoginData, () => [["Request", Request_Login_$reflection()], ["Response", option_type(Response_UserInfo_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FormChanged", "LogInSubmitted", "LogInSucceeded", "LogInFailed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Login.Component.Msg", [], Msg, () => [[["Item", View_Model$1_$reflection(LoginData_$reflection())]], [], [["Item", Response_UserInfo_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [View_idle(new LoginData(new Request_Login("", "", false), void 0)), Cmd_none()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new View_Model$1(model.Values, new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, anonService.Login, model.Values.Request, (Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])))];
        case 2:
            return [new View_Model$1(new LoginData(model.Values.Request, msg.fields[0]), new View_State(3, ["You have been logged in successfully"]), model.ErrorTracking), Router_newUrl(new Route(0, []))];
        case 3:
            return [new View_Model$1(new LoginData(model.Values.Request, void 0), new View_State(2, [exnToDisplayString(msg.fields[0])]), model.ErrorTracking), Cmd_none()];
        default:
            return [msg.fields[0], Cmd_none()];
    }
}

export const form = (() => {
    const usernameField = textField(new FieldConfig$4((value) => (isNullOrWhiteSpace(value) ? (new FSharpResult$2(1, ["Must be not empty"])) : (new FSharpResult$2(0, [value.trim()]))), (values) => values.Request.Username, (newValue, values_1) => {
        let inputRecord;
        return new LoginData((inputRecord = values_1.Request, new Request_Login(newValue, inputRecord.Password, inputRecord.IsPersistent)), values_1.Response);
    }, (_arg) => void 0, new Attributes$1("User name", "User name", empty())));
    const passwordField = passwordField_1(new FieldConfig$4((ResultValue) => (new FSharpResult$2(0, [ResultValue])), (values_2) => values_2.Request.Password, (newValue_1, values_3) => {
        let inputRecord_1;
        return new LoginData((inputRecord_1 = values_3.Request, new Request_Login(inputRecord_1.Username, newValue_1, inputRecord_1.IsPersistent)), values_3.Response);
    }, (_arg_1) => void 0, new Attributes$1("Password", "Your password", empty())));
    return append(checkboxField(new FieldConfig$4((ResultValue_1) => (new FSharpResult$2(0, [ResultValue_1])), (values_4) => values_4.Request.IsPersistent, (newValue_2, values_5) => {
        let inputRecord_2;
        return new LoginData((inputRecord_2 = values_5.Request, new Request_Login(inputRecord_2.Username, inputRecord_2.Password, newValue_2)), values_5.Response);
    }, (_arg_2) => void 0, new Attributes("Remember me"))), append(passwordField, append(usernameField, succeed((username) => ((password) => ((rememberMe_1) => (new Msg(1, []))))))));
})();

export function view(model, dispatch) {
    if (model.State.tag === 3) {
        return "Already logged in";
    }
    else {
        const children_2 = toList(delay(() => append_1(!((process.env["NO_GOOGLE_AUTH"] ? process.env["NO_GOOGLE_AUTH"] : '') === "true") ? append_1(singleton(button(ofArray([new Option(0, [new Color_IColor(3, [])]), new Option(16, [false]), new Option(18, [(_arg) => {
            const scheme = toString(new Request_ExternalAuthScheme());
            const location = window.location;
            const baseUrl = `${location.protocol}//${location.hostname}:${location.port}`;
            const path_2 = AnonymousService_RouteBuilder("", `LoginExt?scheme=${scheme}&next=${encodeURIComponent(`${baseUrl}${AnonymousService_RouteBuilder("", `OnLoginExt?final=${`${baseUrl}${RouteModule_toHash(new Route(4, []))}`}`)}`)}`);
            location.href = (`${baseUrl}${path_2}`);
        }]), new Option(1, [new Size_ISize(2, [])])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fab fa-google"])), []))), createElement("span", {
            children: Interop_reactApi.Children.toArray(["Login with Google"]),
        })]))), delay(() => append_1(singleton(createElement("hr", {})), delay(() => singleton(h2(empty())(singleton_1("Login with password"))))))) : empty_1(), delay(() => singleton(asHtml(new View_ViewConfig$2(dispatch, (Item) => (new Msg(0, [Item])), new View_Action$1(0, ["Log in"]), new View_Validation(1, [])))(form)(model))))));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }
}

