import { Union, Record } from "../fable_modules/fable-library.4.5.0/Types.js";
import { ContactData as ContactData_1, ContactData_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, class_type, unit_type, record_type, bool_type, option_type, string_type } from "../fable_modules/fable-library.4.5.0/Reflection.js";
import { View_ViewConfig$2, View_Validation, View_Action$1, succeed, textareaField, append, emailField as emailField_1, textField, View_Model$1, View_State, View_idle, View_Model$1_$reflection } from "../fable_modules/Fable.Form.Simple.4.0.0/Form.fs.js";
import { value as value_4 } from "../fable_modules/fable-library.4.5.0/Option.js";
import { Cmd_OfPromise_either, Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { curry2 } from "../fable_modules/fable-library.4.5.0/Util.js";
import { anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.5.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.5.0/Choice.js";
import { ofArray, empty } from "../fable_modules/fable-library.4.5.0/List.js";
import { Attributes$1 } from "../fable_modules/Fable.Form.Simple.4.0.0/TextField.fs.js";
import { FieldConfig$4 } from "../fable_modules/Fable.Form.2.0.0/Base.fs.js";
import { createElement } from "react";
import { asHtml } from "../fable_modules/Fable.Form.Simple.Bulma.4.0.0/Form.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class Data extends Record {
    constructor(ContactData, RecaptchaToken, IsRecaptchaReady, LoggedIn) {
        super();
        this.ContactData = ContactData;
        this.RecaptchaToken = RecaptchaToken;
        this.IsRecaptchaReady = IsRecaptchaReady;
        this.LoggedIn = LoggedIn;
    }
}

export function Data_$reflection() {
    return record_type("Omnicv.Client.Pages.ContactForm.Component.Data", [], Data, () => [["ContactData", ContactData_$reflection()], ["RecaptchaToken", option_type(string_type)], ["IsRecaptchaReady", bool_type], ["LoggedIn", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FormChanged", "RecaptchaReady", "Submitted", "RecaptchaSucceeded", "Succeeded", "Failed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.ContactForm.Component.Msg", [], Msg, () => [[["Item", View_Model$1_$reflection(Data_$reflection())]], [], [], [["Item", string_type]], [["Item", unit_type]], [["Item", class_type("System.Exception")]]]);
}

export function init(session) {
    const model = View_idle(new Data(new ContactData_1("", (session.UserInfo != null) ? value_4(session.UserInfo).Email : "", "", void 0, ""), void 0, false, session.LoggedIn));
    return [new View_Model$1(model.Values, new View_State(1, []), model.ErrorTracking), Cmd_none()];
}

export function update(session, msg, model) {
    let inputRecord, inputRecord_1;
    const cmdSubmit = (model_1) => Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, curry2(anonService.Contact)(model_1.Values.ContactData), model_1.Values.RecaptchaToken, () => (new Msg(4, [void 0])), (Item_1) => (new Msg(5, [Item_1])));
    switch (msg.tag) {
        case 2:
            return [new View_Model$1(model.Values, new View_State(1, []), model.ErrorTracking), session.LoggedIn ? cmdSubmit(model) : Cmd_OfPromise_either(() => {
                const action = {
                    action: "submit",
                };
                const key = process.env["GOOGLE_RECAPTCHA_SITE_KEY"] ? process.env["GOOGLE_RECAPTCHA_SITE_KEY"] : '';
                return grecaptcha.execute(key, action);
            }, void 0, (Item_2) => (new Msg(3, [Item_2])), (Item_3) => (new Msg(5, [Item_3])))];
        case 1:
            return [new View_Model$1((inputRecord = model.Values, new Data(inputRecord.ContactData, inputRecord.RecaptchaToken, true, inputRecord.LoggedIn)), new View_State(0, []), model.ErrorTracking), Cmd_none()];
        case 3: {
            const newModel_1 = new View_Model$1((inputRecord_1 = model.Values, new Data(inputRecord_1.ContactData, msg.fields[0], inputRecord_1.IsRecaptchaReady, inputRecord_1.LoggedIn)), new View_State(1, []), model.ErrorTracking);
            return [newModel_1, cmdSubmit(newModel_1)];
        }
        case 4:
            return [new View_Model$1(model.Values, new View_State(3, [`Contact request successful: ${void 0}`]), model.ErrorTracking), Cmd_none()];
        case 5:
            return [new View_Model$1(model.Values, new View_State(2, [exnToDisplayString(msg.fields[0])]), model.ErrorTracking), Cmd_none()];
        default:
            return [msg.fields[0], Cmd_none()];
    }
}

export const form = (() => {
    const nameField = textField(new FieldConfig$4((value) => (isNullOrWhiteSpace(value) ? (new FSharpResult$2(1, ["Must be not empty"])) : (new FSharpResult$2(0, [value.trim()]))), (values) => values.ContactData.Name, (newValue, values_1) => {
        let inputRecord;
        return new Data((inputRecord = values_1.ContactData, new ContactData_1(newValue, inputRecord.Email, inputRecord.Subject, inputRecord.Url, inputRecord.Message)), values_1.RecaptchaToken, values_1.IsRecaptchaReady, values_1.LoggedIn);
    }, (_arg) => void 0, new Attributes$1("Name", "Your name", empty())));
    const emailField = emailField_1(new FieldConfig$4((value_1) => (isNullOrWhiteSpace(value_1) ? (new FSharpResult$2(1, ["Must be not empty"])) : (new FSharpResult$2(0, [value_1.trim()]))), (values_2) => values_2.ContactData.Email, (newValue_1, values_3) => {
        let inputRecord_1;
        return values_3.LoggedIn ? values_3 : (new Data((inputRecord_1 = values_3.ContactData, new ContactData_1(inputRecord_1.Name, newValue_1, inputRecord_1.Subject, inputRecord_1.Url, inputRecord_1.Message)), values_3.RecaptchaToken, values_3.IsRecaptchaReady, values_3.LoggedIn));
    }, (_arg_1) => void 0, new Attributes$1("Email", "Your email address", empty())));
    const subjectField = textField(new FieldConfig$4((value_2) => (isNullOrWhiteSpace(value_2) ? (new FSharpResult$2(1, ["Must be not empty"])) : (new FSharpResult$2(0, [value_2.trim()]))), (values_4) => values_4.ContactData.Subject, (newValue_2, values_5) => {
        let inputRecord_2;
        return new Data((inputRecord_2 = values_5.ContactData, new ContactData_1(inputRecord_2.Name, inputRecord_2.Email, newValue_2, inputRecord_2.Url, inputRecord_2.Message)), values_5.RecaptchaToken, values_5.IsRecaptchaReady, values_5.LoggedIn);
    }, (_arg_2) => void 0, new Attributes$1("Subject", "The subject of your message", empty())));
    return append(textareaField(new FieldConfig$4((value_3) => (isNullOrWhiteSpace(value_3) ? (new FSharpResult$2(1, ["Must be not empty"])) : (new FSharpResult$2(0, [value_3.trim()]))), (values_6) => values_6.ContactData.Message, (newValue_3, values_7) => {
        let inputRecord_3;
        return new Data((inputRecord_3 = values_7.ContactData, new ContactData_1(inputRecord_3.Name, inputRecord_3.Email, inputRecord_3.Subject, inputRecord_3.Url, newValue_3)), values_7.RecaptchaToken, values_7.IsRecaptchaReady, values_7.LoggedIn);
    }, (_arg_3) => void 0, new Attributes$1("Message", "Your message you would like to send us", empty()))), append(subjectField, append(emailField, append(nameField, succeed((_arg_4) => ((_arg_5) => ((_arg_6) => ((_arg_7) => (new Msg(2, []))))))))));
})();

export function view(model, dispatch) {
    if (!model.Values.IsRecaptchaReady) {
        grecaptcha.ready(() => {
            dispatch(new Msg(1, []));
        });
    }
    if (model.State.tag === 3) {
        return "Thank you for contacting us!";
    }
    else {
        const children = ofArray([createElement("p", {
            children: "Use this form to send us any questions, comments, bug reports or suggestions you might have",
        }), asHtml(new View_ViewConfig$2(dispatch, (Item) => (new Msg(0, [Item])), new View_Action$1(0, ["Contact"]), new View_Validation(1, [])))(form)(model)]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

