import { Union, Record } from "../fable_modules/fable-library.4.5.0/Types.js";
import { StaticPage_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, class_type, record_type, bool_type, string_type, option_type } from "../fable_modules/fable-library.4.5.0/Reflection.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { anonService } from "../Omnicv.Client.Common/CommonServer.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.4.5.0/Seq.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../fable_modules/fable-library.4.5.0/Util.js";
import { h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { singleton as singleton_1, empty } from "../fable_modules/fable-library.4.5.0/List.js";
import { content as content_1, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { content as content_2 } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { createElement } from "react";
import { Markdown_ToHtml_1BFEAEDB } from "../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";

export class Model extends Record {
    constructor(Page, Error$, InProgress) {
        super();
        this.Page = Page;
        this.Error = Error$;
        this.InProgress = InProgress;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.StaticPage.Component.Model", [], Model, () => [["Page", option_type(StaticPage_$reflection())], ["Error", option_type(string_type)], ["InProgress", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Succeeded", "Failed"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.StaticPage.Component.Msg", [], Msg, () => [[["Item", StaticPage_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function init(path) {
    return [new Model(void 0, void 0, false), Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, anonService.GetStaticPage, path, (Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])))];
}

export function update(msg, model) {
    if (msg.tag === 1) {
        return [new Model(void 0, exnToDisplayString(msg.fields[0]), false), Cmd_none()];
    }
    else {
        return [new Model(msg.fields[0], model.Error, false), Cmd_none()];
    }
}

export function view(model, dispatch) {
    const children = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton(closeable(`Can't fetch information: ${matchValue}`, new Class(4, []))) : singleton(defaultOf())), delay(() => {
            const matchValue_1 = model.Page;
            if (matchValue_1 != null) {
                const value = matchValue_1;
                return append(singleton(h1(empty())(singleton_1(value.Title))), delay(() => singleton(card(empty(), singleton_1(content_1(empty(), singleton_1(content_2(empty(), singleton_1(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: Markdown_ToHtml_1BFEAEDB(value.Body),
                    },
                }))))))))));
            }
            else {
                return singleton(createElement("i", {
                    children: ["Loading..."],
                }));
            }
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

