import { Union } from "../../../fable-library.4.5.0/Types.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Size_ISize_$reflection } from "../../Common.fs.js";
import { union_type, list_type, class_type, string_type } from "../../../fable-library.4.5.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.4.5.0/MapUtil.js";
import { HTMLAttr } from "../../../Fable.React.9.3.0/Fable.React.Props.fs.js";
import { Reflection_getCaseName } from "../../Common.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "For", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Label.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <label class="label"></label>
 */
export function label(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(96, [option.fields[0]]));
            case 3:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 4:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
        }
    }, "label"), (props_1, children_1) => react.createElement("label", keyValueList(props_1, 1), ...children_1), children);
}

