import { Union, Record } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { union_type, unit_type, int64_type, class_type, record_type, option_type, string_type, bool_type, list_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { filter as filter_1, map, ofArray, singleton, empty } from "../../fable_modules/fable-library.4.5.0/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { curry2 } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { exnToDisplayString } from "../../Omnicv.Client.Common/Utils.js";
import { createFitnessListActions, displayWorkout, FitnessAction, fitnessService } from "./FitnessCommon.js";
import { Router_modifyLocation, Route } from "../../Omnicv.Client.Common/Router.js";
import { singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.4.5.0/Seq.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { h2 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Option, button, list as list_2 } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Option as Option_1, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Markdown_ToHtml_1BFEAEDB } from "../../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { Option as Option_2, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { input } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_3, IInputType } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library.4.5.0/String.js";
import { Option as Option_4, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";

export class Model$1 extends Record {
    constructor(Entries, InProgress, Filter, Error$) {
        super();
        this.Entries = Entries;
        this.InProgress = InProgress;
        this.Filter = Filter;
        this.Error = Error$;
    }
}

export function Model$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Pages.Fitness.FitnessEntriesView.Component.Model`1", [gen0], Model$1, () => [["Entries", list_type(gen0)], ["InProgress", bool_type], ["Filter", string_type], ["Error", option_type(string_type)]]);
}

export function Model$1_Default() {
    return new Model$1(empty(), false, "", void 0);
}

export class Msg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestEntries", "EntriesSuccess", "Failure", "DeleteEntry", "MarkFavourite", "FavouriteSuccess", "DeleteSuccess", "UpdateFilter"];
    }
}

export function Msg$1_$reflection(gen0) {
    return union_type("Omnicv.Client.Pages.Fitness.FitnessEntriesView.Component.Msg`1", [gen0], Msg$1, () => [[], [["Item", list_type(gen0)]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", int64_type]], [["Item", unit_type]], [["Item", unit_type]], [["Item", string_type]]]);
}

export function Common_init() {
    return [Model$1_Default(), singleton((dispatch) => {
        dispatch(new Msg$1(0, []));
    })];
}

export function Common_update(getEntries, deleteEntry, markEntryFavourite, session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model$1(msg.fields[0], false, model.Filter, void 0), Cmd_none()];
        case 4:
            return [new Model$1(model.Entries, true, model.Filter, model.Error), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(markEntryFavourite)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Msg$1(5, [void 0])), (Item_3) => (new Msg$1(2, [Item_3])))];
        case 3:
            return [new Model$1(model.Entries, true, model.Filter, model.Error), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, curry2(deleteEntry)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Msg$1(6, [void 0])), (Item_5) => (new Msg$1(2, [Item_5])))];
        case 6:
        case 5:
            return [new Model$1(model.Entries, false, model.Filter, model.Error), singleton((dispatch) => {
                dispatch(new Msg$1(0, []));
            })];
        case 2:
            return [new Model$1(model.Entries, false, model.Filter, exnToDisplayString(msg.fields[0])), Cmd_none()];
        case 7:
            return [new Model$1(model.Entries, model.InProgress, msg.fields[0], model.Error), Cmd_none()];
        default:
            return [new Model$1(model.Entries, true, model.Filter, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, getEntries, Session__GetCurrentProfileId(session), (Item) => (new Msg$1(1, [Item])), (Item_1) => (new Msg$1(2, [Item_1])))];
    }
}

const Workouts_service = fitnessService;

export function Workouts_init() {
    return Common_init();
}

export function Workouts_update(session, msg, model) {
    return Common_update(Workouts_service.GetWorkouts, Workouts_service.DeleteWorkout, Workouts_service.MarkWorkoutFavourite, session, msg, model);
}

function Workouts_workoutActions(dispatch) {
    return ofArray([new FitnessAction(4, [(Item) => (new Route(38, [Item]))]), new FitnessAction(3, [(Item_1) => (new Route(37, [Item_1]))]), new FitnessAction(6, [(arg) => {
        dispatch(new Msg$1(4, [arg]));
    }]), new FitnessAction(1, [(arg_1) => {
        dispatch(new Msg$1(3, [arg_1]));
    }])]);
}

function Workouts_displayWorkouts(dispatch, workouts) {
    let actions;
    return map((actions = Workouts_workoutActions(dispatch), (workout) => displayWorkout(actions, workout)), workouts);
}

export function Workouts_view(model, dispatch) {
    const children_6 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display workouts: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(h2(empty())(singleton("Workouts"))), delay(() => {
            let children_4, children_2;
            return !model.InProgress ? singleton_1((children_4 = ofArray([list_2(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
                Router_modifyLocation(new Route(36, []));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Add workout"]),
            }))))), (children_2 = Workouts_displayWorkouts(dispatch, model.Entries), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))) : singleton_1("Loading data...");
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

const Exercises_service = fitnessService;

export function Exercises_init() {
    return Common_init();
}

export function Exercises_update(session, msg, model) {
    return Common_update(Exercises_service.GetExercises, Exercises_service.DeleteExercise, Exercises_service.MarkExerciseFavourite, session, msg, model);
}

function Exercises_exerciseActions(dispatch) {
    return ofArray([new FitnessAction(4, [(Item) => (new Route(42, [Item]))]), new FitnessAction(3, [(Item_1) => (new Route(41, [Item_1]))]), new FitnessAction(6, [(arg) => {
        dispatch(new Msg$1(4, [arg]));
    }]), new FitnessAction(1, [(arg_1) => {
        dispatch(new Msg$1(3, [arg_1]));
    }])]);
}

function Exercises_displayExercise(dispatch, exercise) {
    return columns(ofArray([new Option_1(4, []), new Option_1(1, [])]), ofArray([column(empty(), singleton(exercise.Name)), column(empty(), singleton(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(exercise.Description),
        },
    }))), column(empty(), createFitnessListActions(Exercises_exerciseActions(dispatch), exercise.Id))]));
}

function Exercises_displayExercises(dispatch, model) {
    const lowerFilter = model.Filter.toLocaleLowerCase();
    return map((exercise_1) => Exercises_displayExercise(dispatch, exercise_1), filter_1((exercise) => (exercise.Name.toLocaleLowerCase().indexOf(lowerFilter) >= 0), model.Entries));
}

export function Exercises_view(model, dispatch) {
    const children_6 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't display exercises: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(h2(empty())(singleton("Exercises"))), delay(() => {
            let children_4, children_2;
            return !model.InProgress ? singleton_1((children_4 = ofArray([list_2(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
                Router_modifyLocation(new Route(40, []));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Add exercise"]),
            }))))), div(singleton(new Option_2(1, [])), ofArray([input(ofArray([new Option_3(1, [new IInputType(0, [])]), new Option_3(0, [new Size_ISize(0, [])]), new Option_3(12, ["Search"]), new Option_3(8, [model.Filter]), new Option_3(13, [(event) => {
                const elem = event.target;
                const newFilter = isNullOrWhiteSpace(elem.value) ? "" : elem.value;
                if (newFilter !== model.Filter) {
                    dispatch(new Msg$1(7, [newFilter]));
                }
            }])])), icon(ofArray([new Option_4(0, [new Size_ISize(0, [])]), new Option_4(1, [])]), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-search"])), [])))])), (children_2 = Exercises_displayExercises(dispatch, model), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))) : singleton_1("Loading data...");
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

