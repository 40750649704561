import { Union } from "../../fable-library.4.5.0/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.4.5.0/Reflection.js";
import { Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.4.5.0/MapUtil.js";

export class TableOption extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["is-bordered", "is-striped", "is-fullwidth", "is-narrow", "is-hoverable", "CustomClass", "Props", "Modifiers"];
    }
}

export function TableOption_$reflection() {
    return union_type("Fulma.Table.TableOption", [], TableOption, () => [[], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <table class="table"></table>
 */
export function table(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 6:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 5:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "table"), (props_1, children_1) => react.createElement("table", keyValueList(props_1, 1), ...children_1), children);
}

