import { createElement } from "react";
import * as react from "react";
import { ProxyRequestException__get_ResponseText, ProxyRequestException } from "../fable_modules/Fable.Remoting.Client.7.29.0/Types.fs.js";
import { toString, hour, addHours, minute, addMinutes, date as date_1, equals, kind, create, addDays } from "../fable_modules/fable-library.4.5.0/Date.js";
import { map } from "../fable_modules/fable-library.4.5.0/Array.js";
import { parse } from "../fable_modules/fable-library.4.5.0/Int32.js";
import { join, toFail, split } from "../fable_modules/fable-library.4.5.0/String.js";
import * as common from "../../src/javascript/common.js";
import { StringBuilder__Append_Z721C83C5, StringBuilder_$ctor } from "../fable_modules/fable-library.4.5.0/System.Text.js";
import { formatDistanceToNow } from "date-fns";
import { toString as toString_1 } from "../fable_modules/fable-library.4.5.0/Types.js";
import { create as create_1, toString as toString_2, fromSeconds } from "../fable_modules/fable-library.4.5.0/TimeSpan.js";
import { content, background, Option, modal } from "../fable_modules/Fulma.3.0.0/Components/Modal.fs.js";
import { toArray, cons, map as map_1, ofArray, empty, singleton } from "../fable_modules/fable-library.4.5.0/List.js";
import { box$0027 } from "../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Common_GenericOption, Modifier_IModifier, TextAlignment_Option, Screen } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option as Option_1, h3 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.5.0/AsyncBuilder.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.29.0/Extensions.fs.js";
import { find } from "../fable_modules/fable-library.4.5.0/Seq.js";
import { HTMLAttr } from "../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { keyValueList } from "../fable_modules/fable-library.4.5.0/MapUtil.js";
import { defaultArg, orElseWith } from "../fable_modules/fable-library.4.5.0/Option.js";
import { EventInfo__GetClientTz, EventInfo__GetLocationTz } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { round, createObj, int32ToString } from "../fable_modules/fable-library.4.5.0/Util.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { LineChart, Line, Tooltip, YAxis, XAxis, CartesianGrid } from "recharts";
import { toNumber } from "../fable_modules/fable-library.4.5.0/Decimal.js";

export function displayStronglyTypedError(_arg) {
    if (_arg.tag === 1) {
        return createElement("div", {
            children: [_arg.fields[0]],
        });
    }
    else {
        return _arg.fields[0];
    }
}

export function exnToDisplayString(error) {
    if (error instanceof ProxyRequestException) {
        const ex = error;
        return ProxyRequestException__get_ResponseText(ex);
    }
    else {
        return error.message;
    }
}

export function dateStrToDate(now, value) {
    const matchValue = value.toLocaleLowerCase();
    switch (matchValue) {
        case "today":
        case "now":
            return now;
        case "yesterday":
            return addDays(now, -1);
        default: {
            const other = matchValue;
            const parts = map((part) => parse(part, 511, false, 32), split(other, ["-"], void 0, 0), Int32Array);
            if (parts.length !== 3) {
                toFail(`Bad date format: ${other}`);
            }
            return create(parts[0], parts[1], parts[2], 0, 0, 0, 0, kind(now));
        }
    }
}

export function dateStrToDateTime(dateStr) {
    const now = common.getNow();
    const date = dateStrToDate(now, dateStr);
    if (!equals(date_1(date), date_1(now))) {
        return addMinutes(addHours(date, hour(now)), minute(now));
    }
    else {
        return now;
    }
}

export function dateToStrForDiary(date) {
    return toString(date, "yyyy-MM-dd");
}

export const timeFormatOptions = (() => {
    const temp = {};
    temp.includeSeconds = false;
    temp.addSuffix = true;
    return temp;
})();

export function getBasicEventInfoData(eventInfo) {
    const sb = StringBuilder_$ctor();
    StringBuilder__Append_Z721C83C5(sb, formatDistanceToNow(eventInfo.EventTimestamp, timeFormatOptions));
    if ((eventInfo.PlaceId != null) ? true : (eventInfo.PlaceName != null)) {
        StringBuilder__Append_Z721C83C5(sb, " 📌");
    }
    return toString_1(sb);
}

export const strictDistanceOptions = (() => {
    const temp = {};
    temp.addSuffix = true;
    return temp;
})();

export function secondsToHuman(secs) {
    let copyOfStruct = fromSeconds(secs);
    return toString_2(copyOfStruct);
}

export function busyModal(isActive, caption) {
    let children;
    return modal(singleton(new Option(1, [isActive])), ofArray([background(empty(), empty()), content(empty(), singleton(box$0027(singleton(new Common_GenericOption(2, [singleton(new Modifier_IModifier(5, [new Screen(0, []), new TextAlignment_Option(0, [])]))])), ofArray([h3(singleton(new Option_1(7, [])))(singleton(caption)), (children = singleton(Fa_i(ofArray([new Fa_IconOption(11, ["fas fa-spinner"]), new Fa_IconOption(12, []), new Fa_IconOption(16, []), new Fa_IconOption(0, [new Fa_ISize(11, [])])]), [])), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))]))))]));
}

export function handleFileEventEx(onFileLoadStarted, onAbort, onError, onFileLoaded, fileEvent) {
    const files = fileEvent.target.files;
    if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = ((_arg) => {
            onFileLoaded(reader.result);
        });
        onFileLoadStarted(file.name);
        reader.onabort = onAbort;
        reader.onerror = onError;
        reader.readAsArrayBuffer(file);
    }
}

export function handleFileEvent(fileEvent) {
    const files = fileEvent.target.files;
    return singleton_1.Delay(() => singleton_1.Combine((files.length < 1) ? (((() => {
        throw new Error("No files selected");
    })(), singleton_1.Zero())) : singleton_1.Zero(), singleton_1.Delay(() => {
        const file = files[0];
        return singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg) => singleton_1.Return([_arg, file.name]));
    })));
}

export function splitSeconds(durationSecs) {
    const hours = ~~(~~(durationSecs / 60) / 60) | 0;
    return [hours, ~~(durationSecs / 60) - (hours * 60), durationSecs % 60];
}

export function stringToEnum(mapping, value) {
    return find((tupledArg) => {
        let copyOfStruct;
        return value === ((copyOfStruct = tupledArg[0], toString_1(copyOfStruct)));
    }, mapping)[0];
}

export function valuesToSelectOptions(values, name) {
    const optionsFromChoices = map_1((tupledArg) => {
        let copyOfStruct;
        const props = [new HTMLAttr(161, [(copyOfStruct = tupledArg[0], toString_1(copyOfStruct))])];
        return react.createElement("option", keyValueList(props, 1), tupledArg[1]);
    }, values);
    return cons(react.createElement("option", {
        value: "",
    }, `-- Choose ${name} --`), optionsFromChoices);
}

const nightStart = create_1(23, 30, 0);

const nightEnd = create_1(7, 0, 0);

export function isNightOptTz(eventInfo, tzOpt) {
    if (tzOpt == null) {
        return void 0;
    }
    else {
        let timestampInTz;
        const arg_1 = tzOpt;
        timestampInTz = common.dateToLuxonTz(eventInfo.EventTimestamp, arg_1);
        const time = create_1(timestampInTz.hour, timestampInTz.minute, timestampInTz.second);
        return !((time >= nightEnd) && (time <= nightStart));
    }
}

export function isNightOpt(eventInfo) {
    return orElseWith(isNightOptTz(eventInfo, EventInfo__GetLocationTz(eventInfo)), () => isNightOptTz(eventInfo, EventInfo__GetClientTz(eventInfo)));
}

export function isNight(eventInfo) {
    const matchValue = isNightOpt(eventInfo);
    let matchResult;
    if (matchValue != null) {
        if (matchValue) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return true;
        default:
            return false;
    }
}

export function responsiveChart(name, data) {
    let elements, properties, properties_2;
    const properties_5 = ofArray([["data", toArray(data)], ["margin", {
        top: defaultArg(5, 0),
        right: defaultArg(30, 0),
        left: defaultArg(void 0, 0),
        bottom: defaultArg(void 0, 0),
    }], (elements = ofArray([(properties = singleton(["strokeDasharray", join(" ", map(int32ToString, new Int32Array([3, 3])))]), Interop_reactApi_1.createElement(CartesianGrid, createObj(properties))), Interop_reactApi_1.createElement(XAxis, {
        dataKey: (point) => toString(point.Timestamp, "yyyy-MM-dd"),
    }), (properties_2 = singleton(["domain", ["auto", "auto"]]), Interop_reactApi_1.createElement(YAxis, createObj(properties_2))), Interop_reactApi_1.createElement(Tooltip, {}), Interop_reactApi_1.createElement(Line, {
        type: "monotone",
        dataKey: (point_1) => round(toNumber(point_1.Value), 2),
        stroke: "#8884d8",
        fill: "#8884d8",
        name: name,
    })]), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi_1.createElement(LineChart, createObj(properties_5));
}

