import { tryFind, ofList } from "../fable_modules/fable-library.4.5.0/Map.js";
import { ofArray, partition, find, length as length_1, map } from "../fable_modules/fable-library.4.5.0/List.js";
import { comparePrimitives } from "../fable_modules/fable-library.4.5.0/Util.js";
import { class_type } from "../fable_modules/fable-library.4.5.0/Reflection.js";
import { length } from "../fable_modules/fable-library.4.5.0/Seq.js";
import { ofList as ofList_1 } from "../fable_modules/fable-library.4.5.0/Set.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.5.0/Choice.js";
import { value } from "../fable_modules/fable-library.4.5.0/Option.js";
import { DiaryModule_$ctor } from "../modules/Omnicv.Diary.Shared/DiaryAPI.js";
import { PhysicalModule_$ctor } from "./PhysicalAPI.js";
import { FinancialModule_$ctor } from "./FinancialAPI.js";
import { FitnessModule_$ctor } from "./FitnessAPI.js";

export class Registry$1 {
    constructor(init) {
        this.modules = init;
        this.lookup = ofList(map((m) => {
            let copyOfStruct;
            return [((copyOfStruct = m, copyOfStruct.Details)).Name, m];
        }, init), {
            Compare: comparePrimitives,
        });
    }
}

export function Registry$1_$reflection(gen0) {
    return class_type("Omnicv.Shared.Registration.Registry`1", [gen0], Registry$1);
}

export function Registry$1_$ctor_Z1FA48BC9(init) {
    return new Registry$1(init);
}

export function Registry$1__get_Modules(this$) {
    return this$.modules;
}

export function Registry$1__get_Validate(this$) {
    if (length(ofList_1(map((m) => m.Details.Name.toLowerCase(), this$.modules), {
        Compare: comparePrimitives,
    })) !== length_1(this$.modules)) {
        return new FSharpResult$2(1, ["There are duplicate modules"]);
    }
    else {
        return new FSharpResult$2(0, [void 0]);
    }
}

export function Registry$1__GetModule_Z721C83C5(this$, name) {
    return find((m) => {
        let copyOfStruct;
        return ((copyOfStruct = m, copyOfStruct.Details)).Name === name;
    }, Registry$1__get_Modules(this$));
}

export function Registry$1__MatchModules_7F866359(this$, modules) {
    const patternInput = partition((tupledArg) => (tupledArg[1] != null), map((m) => [m, tryFind(m, this$.lookup)], modules));
    return [map((tupledArg_1) => value(tupledArg_1[1]), patternInput[0]), map((tupledArg_2) => tupledArg_2[0], patternInput[1])];
}

export const sharedRegistry = Registry$1_$ctor_Z1FA48BC9(ofArray([DiaryModule_$ctor(), PhysicalModule_$ctor(), FinancialModule_$ctor(), FitnessModule_$ctor()]));

