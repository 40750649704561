import { Record, Union } from "../../fable_modules/fable-library.4.5.0/Types.js";
import { class_type, record_type, list_type, string_type, bool_type, option_type, union_type } from "../../fable_modules/fable-library.4.5.0/Reflection.js";
import { update as update_1, prepareEventInfoForSubmit, Model__UpdateEventInfo_Z45EFA9A8, initCommon as initCommon_1, Msg_$reflection, Model_$reflection } from "./EventInfoManager.js";
import { empty, cons, ofArray, singleton, forAll } from "../../fable_modules/fable-library.4.5.0/List.js";
import { fromInt32, toInt64, compare } from "../../fable_modules/fable-library.4.5.0/BigInt.js";
import { EventInfo_Default_7F9DDECF, EventInfo_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { utcNow } from "../../fable_modules/fable-library.4.5.0/Date.js";
import { Cmd_none, Cmd_batch, Cmd_map } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { value as value_3, some } from "../../fable_modules/fable-library.4.5.0/Option.js";
import { curry2 } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { exnToDisplayString } from "../Utils.js";
import { FlexFormBase$1__Render_2B595, FlexFormState, FlexFormBase$1__set_State_Z3615493C } from "./FlexForm.js";
import { div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.4.5.0/Seq.js";
import { Class, closeable } from "./Notification.js";
import { defaultOf } from "../../fable_modules/fable-library.4.5.0/Util.js";
import { container } from "../../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h1 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { createElement } from "react";

export class DataExpected extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Record", "EditHelper"];
    }
}

export function DataExpected_$reflection() {
    return union_type("Omnicv.Client.Components.AddEditRecordFlexForm.DataExpected", [], DataExpected, () => [[], []]);
}

export class Model$2 extends Record {
    constructor(EIMModel, Record, EditHelperData, InProgress, Error$, DataExpected) {
        super();
        this.EIMModel = EIMModel;
        this.Record = Record;
        this.EditHelperData = EditHelperData;
        this.InProgress = InProgress;
        this.Error = Error$;
        this.DataExpected = DataExpected;
    }
}

export function Model$2_$reflection(gen0, gen1) {
    return record_type("Omnicv.Client.Components.AddEditRecordFlexForm.Model`2", [gen0, gen1], Model$2, () => [["EIMModel", Model_$reflection()], ["Record", gen0], ["EditHelperData", option_type(gen1)], ["InProgress", bool_type], ["Error", option_type(string_type)], ["DataExpected", list_type(DataExpected_$reflection())]]);
}

function Model$2__IsItemReady_149A13CF(this$, dataExpectedItem) {
    if (dataExpectedItem.tag === 1) {
        return this$.EditHelperData != null;
    }
    else {
        return Model$2__IsEditing(this$);
    }
}

export function Model$2__IsReady(this$) {
    return forAll((dataExpectedItem) => Model$2__IsItemReady_149A13CF(this$, dataExpectedItem), this$.DataExpected);
}

export function Model$2__IsEditing(this$) {
    return compare(this$.EIMModel.EventInfo.Id, toInt64(fromInt32(0))) > 0;
}

export class Msg$2 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordAUpdated", "RecordBUpdated", "FetchSuccess", "FetchEditHelperDataSuccess", "Failure", "Submit", "SubmitSuccess", "EIMMsg"];
    }
}

export function Msg$2_$reflection(gen0, gen1) {
    return union_type("Omnicv.Client.Components.AddEditRecordFlexForm.Msg`2", [gen0, gen1], Msg$2, () => [[["Item", EventInfo_$reflection()]], [["Item", gen0]], [["Item1", EventInfo_$reflection()], ["Item2", gen0]], [["Item", gen1]], [["Item", class_type("System.Exception")]], [], [["Item", EventInfo_$reflection()]], [["Item", Msg_$reflection()]]]);
}

function initCommon(id, defaultValue, dataExpected) {
    const patternInput = initCommon_1(EventInfo_Default_7F9DDECF(utcNow()));
    return [new Model$2(patternInput[0], defaultValue, void 0, id != null, void 0, dataExpected), Cmd_map((Item) => (new Msg$2(7, [Item])), patternInput[1])];
}

export function initNew(defaultValue, fetchAction) {
    const patternInput = initCommon(void 0, defaultValue, singleton(new DataExpected(1, [])));
    const newModel = patternInput[0];
    return [new Model$2(newModel.EIMModel, newModel.Record, newModel.EditHelperData, true, newModel.Error, newModel.DataExpected), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchAction, void 0, (Item) => (new Msg$2(3, [Item])), (Item_1) => (new Msg$2(4, [Item_1])))]))];
}

export function initEdit(recordId, defaultValue, fetchAction) {
    const patternInput = initCommon(recordId, defaultValue, singleton(new DataExpected(0, [])));
    const newModel = patternInput[0];
    return [new Model$2(newModel.EIMModel, newModel.Record, newModel.EditHelperData, true, newModel.Error, newModel.DataExpected), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchAction, recordId, (tupledArg) => (new Msg$2(2, [tupledArg[0], tupledArg[1]])), (Item_2) => (new Msg$2(4, [Item_2])))]))];
}

export function initEditWithHelperData(recordId, defaultValue, fetchAction, fetchHelperDataAction) {
    const patternInput = initEdit(recordId, defaultValue, fetchAction);
    const newModel = patternInput[0];
    return [new Model$2(newModel.EIMModel, newModel.Record, newModel.EditHelperData, true, newModel.Error, cons(new DataExpected(1, []), newModel.DataExpected)), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchHelperDataAction, void 0, (Item) => (new Msg$2(3, [Item])), (Item_1) => (new Msg$2(4, [Item_1])))]))];
}

export function update(session, submitAction, successAction, msg, model) {
    switch (msg.tag) {
        case 0:
            return [new Model$2(Model__UpdateEventInfo_Z45EFA9A8(model.EIMModel, msg.fields[0]), model.Record, model.EditHelperData, model.InProgress, model.Error, model.DataExpected), Cmd_none()];
        case 1:
            return [new Model$2(model.EIMModel, msg.fields[0], model.EditHelperData, model.InProgress, model.Error, model.DataExpected), Cmd_none()];
        case 2:
            return [new Model$2(Model__UpdateEventInfo_Z45EFA9A8(model.EIMModel, msg.fields[0]), msg.fields[1], model.EditHelperData, false, model.Error, model.DataExpected), Cmd_none()];
        case 3:
            return [new Model$2(model.EIMModel, model.Record, some(msg.fields[0]), false, model.Error, model.DataExpected), Cmd_none()];
        case 5:
            return [new Model$2(model.EIMModel, model.Record, model.EditHelperData, true, model.Error, model.DataExpected), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(submitAction)(prepareEventInfoForSubmit(session, model.EIMModel)), model.Record, (Item_1) => (new Msg$2(6, [Item_1])), (Item_2) => (new Msg$2(4, [Item_2])))];
        case 6:
            return [new Model$2(model.EIMModel, model.Record, model.EditHelperData, false, model.Error, model.DataExpected), successAction()];
        case 4:
            return [new Model$2(model.EIMModel, model.Record, model.EditHelperData, false, exnToDisplayString(msg.fields[0]), model.DataExpected), Cmd_none()];
        default: {
            const patternInput = update_1(msg.fields[0], model.EIMModel);
            return [new Model$2(patternInput[0], model.Record, model.EditHelperData, model.InProgress, model.Error, model.DataExpected), Cmd_map((Item) => (new Msg$2(7, [Item])), patternInput[1])];
        }
    }
}

export function view(buildForm, title, model, dispatch) {
    let form_1;
    const form = buildForm(model, dispatch);
    FlexFormBase$1__set_State_Z3615493C(form, model.InProgress ? (new FlexFormState(1, [])) : ((model.Error != null) ? (new FlexFormState(2, [value_3(model.Error)])) : (new FlexFormState(0, []))));
    form_1 = form;
    return div(empty(), toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Error: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(container(empty(), singleton(h1(empty())(singleton(title))))), delay(() => (!Model$2__IsReady(model) ? singleton_1(createElement("p", {
            children: "Loading...",
        })) : singleton_1(FlexFormBase$1__Render_2B595(form_1, [model.EIMModel.EventInfo, model.Record])))))));
    })));
}

